//Zara Media
import DSC05971 from "./media/Zara/DSC05971.webp";
import DSC05974 from "./media/Zara/DSC05974.webp";
import DSC05976 from "./media/Zara/DSC05976.webp";
import DSC05977 from "./media/Zara/DSC05977.webp";
import DSC05978 from "./media/Zara/DSC05978.webp";
import DSC05979 from "./media/Zara/DSC05979.webp";
import DSC05980 from "./media/Zara/DSC05980.webp";
import DSC05981 from "./media/Zara/DSC05981.webp";
import DSC05982 from "./media/Zara/DSC05982.webp";
import DSC05984 from "./media/Zara/DSC05984.webp";
import DSC05986 from "./media/Zara/DSC05986.webp";
import DSC05989 from "./media/Zara/DSC05989.webp";
import DSC05990 from "./media/Zara/DSC05990.webp";
import DSC05993 from "./media/Zara/DSC05993.webp";
import DSC05994 from "./media/Zara/DSC05994.webp";
import DSC05995 from "./media/Zara/DSC05995.webp";
import DSC05996 from "./media/Zara/DSC05996.webp";
import DSC05997 from "./media/Zara/DSC05997.webp";
import DSC05999 from "./media/Zara/DSC05999.webp";
//Lisa Media
import DSC06061 from "./media/Lisa/DSC06061.webp";
import DSC06062 from "./media/Lisa/DSC06062.webp";
import DSC06063 from "./media/Lisa/DSC06063.webp";
import DSC06064 from "./media/Lisa/DSC06064.webp";
import DSC06065 from "./media/Lisa/DSC06065.webp";
import DSC06066 from "./media/Lisa/DSC06066.webp";
import DSC06067 from "./media/Lisa/DSC06067.webp";
import DSC06068 from "./media/Lisa/DSC06068.webp";
import DSC06069 from "./media/Lisa/DSC06069.webp";
import DSC06070 from "./media/Lisa/DSC06070.webp";
import DSC06072 from "./media/Lisa/DSC06072.webp";
import DSC06073 from "./media/Lisa/DSC06073.webp";
import DSC06074 from "./media/Lisa/DSC06074.webp";
import DSC06075 from "./media/Lisa/DSC06075.webp";
//Ares Media
import DSC06013 from "./media/Ares/DSC06013.webp";
import DSC06014 from "./media/Ares/DSC06014.webp";
import DSC06016 from "./media/Ares/DSC06016.webp";
import DSC06017 from "./media/Ares/DSC06017.webp";
import DSC06018 from "./media/Ares/DSC06018.webp";
import DSC06019 from "./media/Ares/DSC06019.webp";
import DSC06020 from "./media/Ares/DSC06020.webp";
import DSC06021 from "./media/Ares/DSC06021.webp";
import DSC06022 from "./media/Ares/DSC06022.webp";
//Dizayn Media
import DSC06024 from "./media/Dizayn/DSC06024.webp";
import DSC06025 from "./media/Dizayn/DSC06025.webp";
import DSC06027 from "./media/Dizayn/DSC06027.webp";
import DSC06028 from "./media/Dizayn/DSC06028.webp";
import DSC06029 from "./media/Dizayn/DSC06029.webp";
import DSC06030 from "./media/Dizayn/DSC06030.webp";
import DSC06031 from "./media/Dizayn/DSC06031.webp";
import DSC06032 from "./media/Dizayn/DSC06032.webp";
import DSC06033 from "./media/Dizayn/DSC06033.webp";
import DSC06034 from "./media/Dizayn/DSC06034.webp";
import DSC06035 from "./media/Dizayn/DSC06035.webp";
import DSC06036 from "./media/Dizayn/DSC06036.webp";
import DSC06037 from "./media/Dizayn/DSC06037.webp";
import DSC06038 from "./media/Dizayn/DSC06038.webp";
import DSC06039 from "./media/Dizayn/DSC06039.webp";
//Vals Media
import DSC06040 from "./media/Vals/DSC06040.webp";
import DSC06041 from "./media/Vals/DSC06041.webp";
import DSC06042 from "./media/Vals/DSC06042.webp";
import DSC06044 from "./media/Vals/DSC06044.webp";
import DSC06045 from "./media/Vals/DSC06045.webp";
//Lowa Media
import DSC06000 from "./media/Lowa/DSC06000.webp";
import DSC06002 from "./media/Lowa/DSC06002.webp";
import DSC06003 from "./media/Lowa/DSC06003.webp";
import DSC06004 from "./media/Lowa/DSC06004.webp";
import DSC06005 from "./media/Lowa/DSC06005.webp";
import DSC06007 from "./media/Lowa/DSC06007.webp";
import DSC06008 from "./media/Lowa/DSC06008.webp";
import DSC06009 from "./media/Lowa/DSC06009.webp";
import DSC06010 from "./media/Lowa/DSC06010.webp";
import DSC06011 from "./media/Lowa/DSC06011.webp";
//Opera Media
import DSC06076 from "./media/Opera/DSC06076.webp";
import DSC06077 from "./media/Opera/DSC06077.webp";
import DSC06078 from "./media/Opera/DSC06078.webp";
import DSC06079 from "./media/Opera/DSC06079.webp";
import DSC06080 from "./media/Opera/DSC06080.webp";
import DSC06081 from "./media/Opera/DSC06081.webp";
import DSC06082 from "./media/Opera/DSC06082.webp";
import DSC06083 from "./media/Opera/DSC06083.webp";
import DSC06084 from "./media/Opera/DSC06084.webp";
import DSC06085 from "./media/Opera/DSC06085.webp";
import DSC06086 from "./media/Opera/DSC06086.webp";
import DSC06087 from "./media/Opera/DSC06087.webp";
import DSC06088 from "./media/Opera/DSC06088.webp";
import DSC06089 from "./media/Opera/DSC06089.webp";
import DSC06090 from "./media/Opera/DSC06090.webp";
import DSC06091 from "./media/Opera/DSC06091.webp";
import DSC06092 from "./media/Opera/DSC06092.webp";
import DSC06093 from "./media/Opera/DSC06093.webp";
import DSC06094 from "./media/Opera/DSC06094.webp";
import DSC06095 from "./media/Opera/DSC06095.webp";
import DSC06096 from "./media/Opera/DSC06096.webp";
import DSC06097 from "./media/Opera/DSC06097.webp";
import DSC06098 from "./media/Opera/DSC06098.webp";
import DSC06099 from "./media/Opera/DSC06099.webp";
//Springs Media
import DSC06101 from "./media/Springs/DSC06101.webp";
import DSC06102 from "./media/Springs/DSC06102.webp";
import DSC06103 from "./media/Springs/DSC06103.webp";
import DSC06104 from "./media/Springs/DSC06104.webp";
import DSC06105 from "./media/Springs/DSC06105.webp";
import DSC06106 from "./media/Springs/DSC06106.webp";
//Meredith Media
import DSC06107 from "./media/Meredith/DSC06107.webp";
import DSC06108 from "./media/Meredith/DSC06108.webp";
import DSC06109 from "./media/Meredith/DSC06109.webp";
import DSC06110 from "./media/Meredith/DSC06110.webp";
import DSC06111 from "./media/Meredith/DSC06111.webp";
import DSC06112 from "./media/Meredith/DSC06112.webp";
import DSC06113 from "./media/Meredith/DSC06113.webp";
import DSC06114 from "./media/Meredith/DSC06114.webp";
import DSC06115 from "./media/Meredith/DSC06115.webp";
import DSC06116 from "./media/Meredith/DSC06116.webp";
import DSC06117 from "./media/Meredith/DSC06117.webp";
import DSC06118 from "./media/Meredith/DSC06118.webp";
import DSC06119 from "./media/Meredith/DSC06119.webp";
import DSC06122 from "./media/Meredith/DSC06122.webp";
import DSC06121 from "./media/Meredith/DSC06121.webp";

//Zara 3D models
import DSC059713D from "./media/Zara3D/DSC05971.glb";
import DSC059743D from "./media/Zara3D/DSC05974.glb";
import DSC059763D from "./media/Zara3D/DSC05976.glb";
import DSC059773D from "./media/Zara3D/DSC05977.glb";
import DSC059783D from "./media/Zara3D/DSC05978.glb";
import DSC059793D from "./media/Zara3D/DSC05979.glb";
import DSC059803D from "./media/Zara3D/DSC05980.glb";
import DSC059813D from "./media/Zara3D/DSC05981.glb";
import DSC059823D from "./media/Zara3D/DSC05982.glb";
import DSC059843D from "./media/Zara3D/DSC05984.glb";
import DSC059863D from "./media/Zara3D/DSC05986.glb";
import DSC059893D from "./media/Zara3D/DSC05989.glb";
import DSC059903D from "./media/Zara3D/DSC05990.glb";
import DSC059933D from "./media/Zara3D/DSC05993.glb";
import DSC059943D from "./media/Zara3D/DSC05994.glb";
import DSC059953D from "./media/Zara3D/DSC05995.glb";
import DSC059963D from "./media/Zara3D/DSC05996.glb";
import DSC059973D from "./media/Zara3D/DSC05997.glb";
import DSC059993D from "./media/Zara3D/DSC05999.glb";
//Lowa 3D models
import DSC060003D from "./media/Lowa3D/DSC06000.glb";
import DSC060023D from "./media/Lowa3D/DSC06002.glb";
import DSC060033D from "./media/Lowa3D/DSC06003.glb";
import DSC060043D from "./media/Lowa3D/DSC06004.glb";
import DSC060053D from "./media/Lowa3D/DSC06005.glb";
import DSC060073D from "./media/Lowa3D/DSC06007.glb";
import DSC060083D from "./media/Lowa3D/DSC06008.glb";
import DSC060093D from "./media/Lowa3D/DSC06009.glb";
import DSC060103D from "./media/Lowa3D/DSC06010.glb";
import DSC060113D from "./media/Lowa3D/DSC06011.glb";

//Ares 3D models
import DSC060133D from "./media/Ares3D/DSC06013.glb";
import DSC060143D from "./media/Ares3D/DSC06014.glb";
import DSC060163D from "./media/Ares3D/DSC06016.glb";
import DSC060173D from "./media/Ares3D/DSC06017.glb";
import DSC060183D from "./media/Ares3D/DSC06018.glb";
import DSC060193D from "./media/Ares3D/DSC06019.glb";
import DSC060203D from "./media/Ares3D/DSC06020.glb";
import DSC060213D from "./media/Ares3D/DSC06021.glb";
import DSC060223D from "./media/Ares3D/DSC06022.glb";
//Dizayn 3D models
import DSC060243D from "./media/Dizayn3D/DSC06024.glb";
import DSC060253D from "./media/Dizayn3D/DSC06025.glb";
import DSC060273D from "./media/Dizayn3D/DSC06027.glb";
import DSC060283D from "./media/Dizayn3D/DSC06028.glb";
import DSC060293D from "./media/Dizayn3D/DSC06029.glb";
import DSC060303D from "./media/Dizayn3D/DSC06030.glb";
import DSC060313D from "./media/Dizayn3D/DSC06031.glb";
import DSC060323D from "./media/Dizayn3D/DSC06032.glb";
import DSC060333D from "./media/Dizayn3D/DSC06033.glb";
import DSC060343D from "./media/Dizayn3D/DSC06034.glb";
import DSC060353D from "./media/Dizayn3D/DSC06035.glb";
import DSC060363D from "./media/Dizayn3D/DSC06036.glb";
import DSC060373D from "./media/Dizayn3D/DSC06037.glb";
import DSC060383D from "./media/Dizayn3D/DSC06038.glb";
import DSC060393D from "./media/Dizayn3D/DSC06039.glb";
//Vals 3D models
import DSC060403D from "./media/Vals3D/DSC06040.glb";
import DSC060413D from "./media/Vals3D/DSC06041.glb";
import DSC060423D from "./media/Vals3D/DSC06042.glb";
import DSC060443D from "./media/Vals3D/DSC06044.glb";
import DSC060453D from "./media/Vals3D/DSC06045.glb";
//Lisa 3D models
import DSC060613D from "./media/Lisa3D/DSC06061.glb";
import DSC060623D from "./media/Lisa3D/DSC06062.glb";
import DSC060633D from "./media/Lisa3D/DSC06063.glb";
import DSC060643D from "./media/Lisa3D/DSC06064.glb";
import DSC060653D from "./media/Lisa3D/DSC06065.glb";
import DSC060663D from "./media/Lisa3D/DSC06066.glb";
import DSC060673D from "./media/Lisa3D/DSC06067.glb";
import DSC060683D from "./media/Lisa3D/DSC06068.glb";
import DSC060693D from "./media/Lisa3D/DSC06069.glb";
import DSC060703D from "./media/Lisa3D/DSC06070.glb";
import DSC060723D from "./media/Lisa3D/DSC06072.glb";
import DSC060733D from "./media/Lisa3D/DSC06073.glb";
import DSC060743D from "./media/Lisa3D/DSC06074.glb";
import DSC060753D from "./media/Lisa3D/DSC06075.glb";
//Srpings 3D models
import DSC061013D from "./media/Springs3D/DSC06101.glb";
import DSC061023D from "./media/Springs3D/DSC06102.glb";
import DSC061033D from "./media/Springs3D/DSC06103.glb";
import DSC061043D from "./media/Springs3D/DSC06104.glb";
import DSC061053D from "./media/Springs3D/DSC06105.glb";
import DSC061063D from "./media/Springs3D/DSC06106.glb";
//Opera 3D models
import DSC060763D from "./media/Opera3D/DSC06076.glb";
import DSC060773D from "./media/Opera3D/DSC06077.glb";
import DSC060783D from "./media/Opera3D/DSC06078.glb";
import DSC060793D from "./media/Opera3D/DSC06079.glb";
import DSC060803D from "./media/Opera3D/DSC06080.glb";
import DSC060813D from "./media/Opera3D/DSC06081.glb";
import DSC060823D from "./media/Opera3D/DSC06082.glb";
import DSC060833D from "./media/Opera3D/DSC06083.glb";
import DSC060843D from "./media/Opera3D/DSC06084.glb";
import DSC060853D from "./media/Opera3D/DSC06085.glb";
import DSC060863D from "./media/Opera3D/DSC06086.glb";
import DSC060873D from "./media/Opera3D/DSC06087.glb";
import DSC060883D from "./media/Opera3D/DSC06088.glb";
import DSC060893D from "./media/Opera3D/DSC06089.glb";
import DSC060903D from "./media/Opera3D/DSC06090.glb";
import DSC060913D from "./media/Opera3D/DSC06091.glb";
import DSC060923D from "./media/Opera3D/DSC06092.glb";
import DSC060933D from "./media/Opera3D/DSC06093.glb";
import DSC060943D from "./media/Opera3D/DSC06094.glb";
import DSC060953D from "./media/Opera3D/DSC06095.glb";
import DSC060963D from "./media/Opera3D/DSC06096.glb";
import DSC060973D from "./media/Opera3D/DSC06097.glb";
import DSC060983D from "./media/Opera3D/DSC06098.glb";
import DSC060993D from "./media/Opera3D/DSC06099.glb";
//Meredith 3D models
import DSC061073D from "./media/Meredith3D/DSC06107.glb";
import DSC061083D from "./media/Meredith3D/DSC06108.glb";
import DSC061093D from "./media/Meredith3D/DSC06109.glb";
import DSC061103D from "./media/Meredith3D/DSC06110.glb";
import DSC061113D from "./media/Meredith3D/DSC06111.glb";
import DSC061123D from "./media/Meredith3D/DSC06112.glb";
import DSC061133D from "./media/Meredith3D/DSC06113.glb";
import DSC061143D from "./media/Meredith3D/DSC06114.glb";
import DSC061153D from "./media/Meredith3D/DSC06115.glb";
import DSC061163D from "./media/Meredith3D/DSC06116.glb";
import DSC061173D from "./media/Meredith3D/DSC06117.glb";
import DSC061183D from "./media/Meredith3D/DSC06118.glb";
import DSC061193D from "./media/Meredith3D/DSC06119.glb";
import DSC061213D from "./media/Meredith3D/DSC06121.glb";
import DSC061223D from "./media/Meredith3D/DSC06122.glb";

//Decora Media

import D11751G12 from "./media/Decora/D11751G12.webp";
import D12486 from "./media/Decora/D12486.webp";
import D12612W17 from "./media/Decora/D12612W17.webp";
import D14667 from "./media/Decora/D14667.webp";
import D14984 from "./media/Decora/D14984.webp";
import D14987 from "./media/Decora/D14987.webp";
import D14988 from "./media/Decora/D14988.webp";
import D15844 from "./media/Decora/D15844.webp";
//3D Decora
import D11751G123D from "./media/Decora3D/D11751G12.glb";
import D124863D from "./media/Decora3D/D12486.glb";
import D12612W173D from "./media/Decora3D/D12612W17.glb";
import D146673D from "./media/Decora3D/D14667.glb";
import D149843D from "./media/Decora3D/D14984.glb";
import D149873D from "./media/Decora3D/D14987.glb";
import D149883D from "./media/Decora3D/D14988.glb";
import D158443D from "./media/Decora3D/D15844.glb";
//Gora Media
import G24130 from "./media/Gora/G24130.webp";
import G24130A from "./media/Gora/G24130A.webp";
import G24131A from "./media/Gora/G24131A.webp";
import G24138A from "./media/Gora/G24138A.webp";
import G24142A from "./media/Gora/G24142A.webp";
//Gora 3D
import G241303D from "./media/Gora3D/G24130.glb";
import G24130A3D from "./media/Gora3D/G24130A.glb";
import G24131A3D from "./media/Gora3D/G24131A.glb";
import G24138A3D from "./media/Gora3D/G24138A.glb";
import G24142A3D from "./media/Gora3D/G24142A.glb";
//Gora Plus Media
import GP24165A from "./media/GoraPlus/GP24165A.webp";
import GPl24165A from "./media/GoraPlus/GPl24165A.webp";
import GPlus24165A from "./media/GoraPlus/GPlus24165A.webp";
//Gora Plus 3D
import GP24165A3D from "./media/GoraPlus3D/GP24165A.glb";
import GPl24165A3D from "./media/GoraPlus3D/GPl24165A.glb";
import GPlus24165A3D from "./media/GoraPlus3D/GPlus24165A.glb";
//Kraft Media
import K20023 from "./media/Kraft/K20023.webp";
import K20443 from "./media/Kraft/K20443.webp";
import K21691 from "./media/Kraft/K21691.webp";
import K22816 from "./media/Kraft/K22816.webp";
//Kraft Media 3D
import K200233D from "./media/Kraft3D/K20023.glb";
import K204433D from "./media/Kraft3D/K20443.glb";
import K216913D from "./media/Kraft3D/K21691.glb";
import K228163D from "./media/Kraft3D/K22816.glb";
//Pastoral Media
import P26551 from "./media/Pastoral/P26551.webp";
import P26702 from "./media/Pastoral/P26702.webp";
import P26704A from "./media/Pastoral/P26704A.webp";
import P26716D from "./media/Pastoral/P26716D.webp";
import P26723A from "./media/Pastoral/P26723A.webp";
import P26750A from "./media/Pastoral/P26750A.webp";
import P26800A from "./media/Pastoral/P26800A.webp";
import P26818A from "./media/Pastoral/P26818A.webp";
import P27395A from "./media/Pastoral/P27395A.webp";
import P27403A from "./media/Pastoral/P27403A.webp";
import P27413A from "./media/Pastoral/P27413A.webp";
//Pastoral Media 3D
import P265513D from "./media/Pastoral3D/P26551.glb";
import P267023D from "./media/Pastoral3D/P26702.glb";
import P26704A3D from "./media/Pastoral3D/P26704A.glb";
import P26716D3D from "./media/Pastoral3D/P26716D.glb";
import P26723A3D from "./media/Pastoral3D/P26723A.glb";
import P26750A3D from "./media/Pastoral3D/P26750A.glb";
import P26800A3D from "./media/Pastoral3D/P26800A.glb";
import P26818A3D from "./media/Pastoral3D/P26818A.glb";
import P27395A3D from "./media/Pastoral3D/P27395A.glb";
import P27403A3D from "./media/Pastoral3D/P27403A.glb";
import P27413A3D from "./media/Pastoral3D/P27413A.glb";
//Tarz Media
import T22089A from "./media/Tarz/T22089A.webp";
import T22196A from "./media/Tarz/T22196A.webp";
import T22175A from "./media/Tarz/T22175A.webp";
import T2211A from "./media/Tarz/T2211A.webp";
//Tarz Media 3D
import T22089A3D from "./media/Tarz3D/T22089A.glb";
import T22196A3D from "./media/Tarz3D/T22196A.glb";
import T22175A3D from "./media/Tarz3D/T22175A.glb";
import T2211A3D from "./media/Tarz3D/T2211A.glb";
//Uptoia Media
import U24596A from "./media/Utopia/U24596A.webp";
import U24605A from "./media/Utopia/U24605A.webp";
import U24607A from "./media/Utopia/U24607A.webp";
//Utopia Media 3D
import U24596A3D from "./media/Utopia3D/U24596A.glb";
import U24605A3D from "./media/Utopia3D/U24605A.glb";
import U24607A3D from "./media/Utopia3D/U24607A.glb";
//Valenica Media
import V21769B from "./media/Valenica/V21769B.webp";
import V21770A from "./media/Valenica/V21770A.webp";
import V22185B from "./media/Valenica/V22185B.webp";
import V22620A from "./media/Valenica/V22620A.webp";
import V22621A from "./media/Valenica/V22621A.webp";
import V22622A from "./media/Valenica/V22622A.webp";
import V22623A from "./media/Valenica/V22623A.webp";
import V26030A from "./media/Valenica/V26030A.webp";
import V26035A from "./media/Valenica/V26035A.webp";
import V26081A from "./media/Valenica/V26081A.webp";
import V26164A from "./media/Valenica/V26164A.webp";
//Valenica Media 3D
import V21769B3D from "./media/Valencia3D/V21769B.glb";
import V21770A3D from "./media/Valencia3D/V21770A.glb";
import V22185B3D from "./media/Valencia3D/V22185B.glb";
import V22620A3D from "./media/Valencia3D/V22620A.glb";
import V22621A3D from "./media/Valencia3D/V22621A.glb";
import V22622A3D from "./media/Valencia3D/V22622A.glb";
import V22623A3D from "./media/Valencia3D/V22623A.glb";
import V26030A3D from "./media/Valencia3D/V26030A.glb";
import V26035A3D from "./media/Valencia3D/V26035A.glb";
import V26081A3D from "./media/Valencia3D/V26081A.glb";
import V26164A3D from "./media/Valencia3D/V26164A.glb";
//Venita Media
import V11961G13 from "./media/Veinta/V11961G13.webp";
import V11961V13 from "./media/Veinta/V11961V13.webp";
import V11976V13 from "./media/Veinta/V11976V13.webp";
import V13596G13 from "./media/Veinta/V13596G13.webp";
import V15260G10 from "./media/Veinta/V15260G10.webp";
import V15260V10 from "./media/Veinta/V15260V10.webp";
import V15261V10 from "./media/Veinta/V15261V10.webp";
import V15264K10 from "./media/Veinta/V15264K10.webp";
import V15265V10 from "./media/Veinta/V15265V10.webp";
import V15266G10 from "./media/Veinta/V15266G10.webp";
import V15267G10 from "./media/Veinta/V15267G10.webp";
import V15267V10 from "./media/Veinta/V15267V10.webp";
import V15267W10 from "./media/Veinta/V15267W10.webp";
import V15270V10 from "./media/Veinta/V15270V10.webp";
import V15275V10 from "./media/Veinta/V15275V10.webp";
import V15371G10 from "./media/Veinta/V15371G10.webp";
//Venita Media 3D
import V11961G133D from "./media/Venita3D/V11961G13.glb";
import V11961V133D from "./media/Venita3D/V11961V13.glb";
import V11976V133D from "./media/Venita3D/V11976V13.glb";
import V13596G133D from "./media/Venita3D/V13596G13.glb";
import V15260G103D from "./media/Venita3D/V15260G10.glb";
import V15260V103D from "./media/Venita3D/V15260V10.glb";
import V15261V103D from "./media/Venita3D/V15261V10.glb";
import V15264K103D from "./media/Venita3D/V15264K10.glb";
import V15265V103D from "./media/Venita3D/V15265V10.glb";
import V15266G103D from "./media/Venita3D/V15266G10.glb";
import V15267G103D from "./media/Venita3D/V15267G10.glb";
import V15267V103D from "./media/Venita3D/V15267V10.glb";
import V15267W103D from "./media/Venita3D/V15267W10.glb";
import V15270V103D from "./media/Venita3D/V15270V10.glb";
import V15275V103D from "./media/Venita3D/V15275V10.glb";
import V15371G103D from "./media/Venita3D/V15371G10.glb";
//Veinca Media
import VL9112A from "./media/Venica/VL9112A.webp";
import VL9142A from "./media/Venica/VL9142A.webp";
import VL9148B from "./media/Venica/VL9148B.webp";
import VL9306A from "./media/Venica/VL9306A.webp";
import VLA785A from "./media/Venica/VLA785A.webp";
import VLB658A from "./media/Venica/VLB658A.webp";
//Veinca Media 3D
import VL9112A3D from "./media/Venica3D/VL9112A.glb";
import VL9142A3D from "./media/Venica3D/VL9142A.glb";
import VL9148B3D from "./media/Venica3D/VL9148B.glb";
import VL9306A3D from "./media/Venica3D/VL9306A.glb";
import VLA785A3D from "./media/Venica3D/VLA785A.glb";
import VLB658A3D from "./media/Venica3D/VLB658A.glb";

//Viera Media
import D694A from "./media/VieraUltra/D694A.webp";
import D694AA from "./media/VieraUltra/D694AA.webp";

//Viera Media 3D
import D694A3D from "./media/VieraUltra3D/D694A.glb";
import D694AA3D from "./media/VieraUltra3D/D694AA.glb";

//Fiara Media
import F18714A from "./media/Fiara/18714A.webp";
import F18709E from "./media/Fiara/18709E.webp";
import F20165B from "./media/Fiara/20165B.webp";
import F20434A from "./media/Fiara/20434A.webp";
import F20434AA from "./media/Fiara/20434AA.webp";
import F22486A from "./media/Fiara/22486A.webp";

//Fiara Media 3D
import F18714A3D from "./media/Fiara3D/18714A.glb";
import F18709E3D from "./media/Fiara3D/18709E.glb";
import F20165B3D from "./media/Fiara3D/20165B.glb";
import F20434A3D from "./media/Fiara3D/20434A.glb";
import F20434AA3D from "./media/Fiara3D/20434AA.glb";
import F22486A3D from "./media/Fiara3D/22486A.glb";


//Amber Media
import A30786A from "./media/Amber/30786A.webp";
import A29876A from "./media/Amber/29876A.webp";
import A29943B from "./media/Amber/29943B.webp";
import A30231C from "./media/Amber/30231C.webp";
import A30286A from "./media/Amber/30286A.webp";
import A30598B from "./media/Amber/30598B.webp";
import A30956B from "./media/Amber/30956B.webp";

//Amber Media 3D 
import A30786A3D from "./media/Amber3D/30786A.glb";
import A29876A3D from "./media/Amber3D/29876A.glb";
import A29943B3D from "./media/Amber3D/29943B.glb";
import A30231C3D from "./media/Amber3D/30231C.glb";
import A30286A3D from "./media/Amber3D/30286A.glb";
import A30598B3D from "./media/Amber3D/30598B.glb";
import A30956B3D from "./media/Amber3D/30956B.glb";


//Julian Media
import E137AP from "./media/Julian/E137AP.webp";
import D789AP from "./media/Julian/D789AP.webp";
import D789AW from "./media/Julian/D789AW.webp";
import D798AW from "./media/Julian/D798AW.webp";
import E135AG from "./media/Julian/E135AG.webp";
import E135AW from "./media/Julian/E135AW.webp";
import E137AW from "./media/Julian/E137AW.webp";

//Julian 3D Media
import E137AP3D from "./media/Julian3D/E137AP.glb";
import D789AP3D from "./media/Julian3D/D789AP.glb";
import D789AW3D from "./media/Julian3D/D789AW.glb";
import D798AW3D from "./media/Julian3D/D798AW.glb";
import E135AG3D from "./media/Julian3D/E135AG.glb";
import E135AW3D from "./media/Julian3D/E135AW.glb";
import E137AW3D from "./media/Julian3D/E137AW.glb";

//Angel Media
import E501AH from "./media/Angel/E501AH.webp";
import E256AD from "./media/Angel/E256AD.webp";
import E364AE from "./media/Angel/E364AE.webp";
import E364AS from "./media/Angel/E364AS.webp";
import E365AE from "./media/Angel/E365AE.webp";
import E379AG from "./media/Angel/E379AG.webp";
import E408AR from "./media/Angel/E408AR.webp";
import E409AN from "./media/Angel/E501AH.webp";
import E409AW from "./media/Angel/E409AW.webp";
import E765AG from "./media/Angel/E765AG.webp";
import E801AG from "./media/Angel/E801AG.webp";
import EO1459A from "./media/Angel/O1459A.webp";

//Angel 3D Media
import E501AH3D from "./media/Angel3D/E501AH.glb";
import E256AD3D from "./media/Angel3D/E256AD.glb";
import E364AE3D from "./media/Angel3D/E364AE.glb";
import E364AS3D from "./media/Angel3D/E364AS.glb";
import E365AE3D from "./media/Angel3D/E365AE.glb";
import E379AG3D from "./media/Angel3D/E379AG.glb";
import E408AR3D from "./media/Angel3D/E408AR.glb";
import E409AN3D from "./media/Angel3D/E501AH.glb";
import E409AW3D from "./media/Angel3D/E409AW.glb";
import E765AG3D from "./media/Angel3D/E765AG.glb";
import E801AG3D from "./media/Angel3D/E801AG.glb";
import EO1459A3D from "./media/Angel3D/O1459A.glb";


//Anett Media
import D647A from "./media/Anett/D674A.webp";
import D307A from "./media/Anett/D307A.webp";
import D573A from "./media/Anett/D573A.webp";
import D574A from "./media/Anett/D574A.webp";
import D574AA from "./media/Anett/D574AA.webp";
import D578B from "./media/Anett/D578B.webp";
import D581A from "./media/Anett/D581A.webp";
import D646A from "./media/Anett/D646A.webp";
import D743A from "./media/Anett/D743A.webp";

//Anett 3D Media
import D674A3D from "./media/Anett3D/D674A.glb";
import D307A3D from "./media/Anett3D/D307A.glb";
import D573A3D from "./media/Anett3D/D573A.glb";
import D574A3D from "./media/Anett3D/D574A.glb";
import D574AA3D from "./media/Anett3D/D574AA.glb";
import D578B3D from "./media/Anett3D/D578B.glb";
import D581A3D from "./media/Anett3D/D581A.glb";
import D646A3D from "./media/Anett3D/D646A.glb";
import D743A3D from "./media/Anett3D/D743A.glb";

const cardsData = [

//Anett 

{
  title: "Anett",
  image: D578B,
  description: "Cream",
  category: "anett",
  nestedCard: {
    carpet: "E365AE",
    image: D578B,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D578B3D,
  },
},
{
  title: "Anett",
  image: D647A,
  description: "Grey",
  category: "anett",
  nestedCard: {
    carpet: "D647A",
    image: D647A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D674A3D,
  },
},{
  title: "Anett",
  image: D574A,
  description: "Beige",
  category: "anett",
  nestedCard: {
    carpet: "D574A",
    image: D574A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D574A3D,
  },
},{
  title: "Anett",
  image: D573A,
  description: "Beige",
  category: "anett",
  nestedCard: {
    carpet: "D573A",
    image: D573A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D573A3D,
  },
},{
  title: "Anett",
  image: D307A,
  description: "Cream",
  category: "anett",
  nestedCard: {
    carpet: "D307A",
    image: D307A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D307A3D,
  },
},{
  title: "Anett",
  image: D574AA,
  description: "Cream",
  category: "anett",
  nestedCard: {
    carpet: "D574AA",
    image: D574AA,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D574AA3D,
  },
},{
  title: "Anett",
  image: D743A,
  description: "L.Grey",
  category: "anett",
  nestedCard: {
    carpet: "D743A",
    image: D743A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D743A3D,
  },
},{
  title: "Anett",
  image: D581A,
  description: "Cream",
  category: "anett",
  nestedCard: {
    carpet: "D581A",
    image: D581A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D581A3D,
  },
},{
  title: "Anett",
  image: D646A,
  description: "Cream",
  category: "anett",
  nestedCard: {
    carpet: "D646A",
    image: D646A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D646A3D,
  },
},

//Angel 

{
  title: "Angel",
  image: E365AE,
  description: "Beige/Brown",
  category: "angel",
  nestedCard: {
    carpet: "E365AE",
    image: E365AE,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E365AE3D,
  },
},
{
  title: "Angel",
  image: E364AE,
  description: "White/Beige",
  category: "angel",
  nestedCard: {
    carpet: "E364AE",
    image: E364AE,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E364AE3D,
  },
},
{
  title: "Angel",
  image: E409AW,
  description: "Grey/D. Grey",
  category: "angel",
  nestedCard: {
    carpet: "E409AW",
    image: E409AW,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E409AW3D,
  },
},{
  title: "Angel",
  image: E765AG,
  description: "L.Grey/D.Grey",
  category: "angel",
  nestedCard: {
    carpet: "E765AG",
    image: E765AG,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E765AG3D,
  },
},{
  title: "Angel",
  image: E256AD,
  description: "Beige/Beige",
  category: "angel",
  nestedCard: {
    carpet: "E256AD",
    image: E256AD,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E256AD3D,
  },
},{
  title: "Angel",
  image: EO1459A,
  description: "Beige/White",
  category: "angel",
  nestedCard: {
    carpet: "O1459A",
    image: EO1459A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: EO1459A3D,
  },
},{
  title: "Angel",
  image: E364AS,
  description: "White/Grey",
  category: "angel",
  nestedCard: {
    carpet: "E364AS",
    image: E364AS,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E364AS3D,
  },
},{
  title: "Angel",
  image: E501AH,
  description: "Beige/D. Beige",
  category: "angel",
  nestedCard: {
    carpet: "E501AH",
    image: E501AH,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E501AH3D,
  },
},{
  title: "Angel",
  image: E801AG,
  description: "White/Grey",
  category: "angel",
  nestedCard: {
    carpet: "E801AG",
    image: E801AG,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E801AG3D,
  },
},{
  title: "Angel",
  image: E379AG,
  description: "Beige/Brown",
  category: "angel",
  nestedCard: {
    carpet: "E379AG",
    image: E379AG,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E379AG3D,
  },
},{
  title: "Angel",
  image: E408AR,
  description: "Grey/L.Grey",
  category: "angel",
  nestedCard: {
    carpet: "D694A",
    image: E408AR,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E408AR3D,
  },
},{
  title: "Angel",
  image: E409AN,
  description: "Cream/Beige",
  category: "angel",
  nestedCard: {
    carpet: "E409AN",
    image: E409AN,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E409AN3D,
  },
},


//Viera Ultra

{
  title: "Viera Ultra",
  image: D694A,
  description: "Beige",
  category: "viera",
  nestedCard: {
    carpet: "D694A",
    image: D694A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D694AA3D,
  },
},{
  title: "Viera Ultra",
  image: D694AA,
  description: "D.Grey",
  category: "viera",
  nestedCard: {
    carpet: "D694A",
    image: D694AA,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D694A3D,
  },
},

//Fiara 

{
  title: "Fiara",
  image: F20434A,
  description: "Grey Shr/Grey",
  category: "fiara",
  nestedCard: {
    carpet: "20434A",
    image: F20434A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F20434A3D,
  },
},{
  title: "Fiara",
  image: F18709E,
  description: "Bone/Grey Shr",
  category: "fiara",
  nestedCard: {
    carpet: "18709E",
    image: F18709E,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F18709E3D,
  },
},
{
  title: "Fiara",
  image: F18714A,
  description: "Bone/Grey Shr",
  category: "fiara",
  nestedCard: {
    carpet: "18714A",
    image: F18714A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F18714A3D,
  },
},{
  title: "Fiara",
  image: F22486A,
  description: "D.Beige/Grey Shr",
  category: "fiara",
  nestedCard: {
    carpet: "22486A",
    image: F22486A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F22486A3D,
  },
},
{
  title: "Fiara",
  image: F20434AA,
  description: "Brown/Grey Shr",
  category: "fiara",
  nestedCard: {
    carpet: "20434A",
    image: F20434AA,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F20434AA3D,
  },
},{
  title: "Fiara",
  image: F20165B,
  description: "Grey Shr/D. Beige",
  category: "fiara",
  nestedCard: {
    carpet: "20165B",
    image: F20165B,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: F20165B3D,
  },
},
//Amber 

{
  title: "Amber",
  image: A30786A,
  description: "L. Grey Shr/Cream",
  category: "amber",
  nestedCard: {
    carpet: "30786A",
    image: A30786A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A30786A3D,
  },
},{
  title: "Amber",
  image: A29876A,
  description: "Cream/Beige Shr",
  category: "amber",
  nestedCard: {
    carpet: "18709E",
    image: A29876A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A29876A3D,
  },
},
{
  title: "Amber",
  image: A30286A,
  description: "Cream/L. Grey Shr",
  category: "amber",
  nestedCard: {
    carpet: "30286A",
    image: A30286A,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A30286A3D,
  },
},{
  title: "Amber",
  image: A30231C,
  description: "L. Grey Shr/Grey Shr",
  category: "amber",
  nestedCard: {
    carpet: "30231C",
    image: A30231C,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A30231C3D,
  },
},
{
  title: "Amber",
  image: A30956B,
  description: "L. Grey Shr/Cream",
  category: "amber",
  nestedCard: {
    carpet: "30956B",
    image: A30956B,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A30956B3D,
  },
},{
  title: "Amber",
  image: A30598B,
  description: "Cream/Beige Shr",
  category: "amber",
  nestedCard: {
    carpet: "30598B",
    image: A30598B,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A30598B3D,
  },
  
},{
  title: "Amber",
  image: A29943B,
  description: "Cream/Beige Shr",
  category: "amber",
  nestedCard: {
    carpet: "29943B",
    image: A29943B,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: A29943B3D,
  },
  
},
//Julian 

{
  title: "Julian",
  image: D798AW,
  description: "White",
  category: "julian",
  nestedCard: {
    carpet: "D798AW",
    image: D798AW,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D798AW3D,
  },
},{
  title: "Julian",
  image: D789AW,
  description: "White",
  category: "julian",
  nestedCard: {
    carpet: "D789AW",
    image: D789AW,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D789AW3D,
  },
},{
  title: "Julian",
  image: E137AP,
  description: "Beige",
  category: "julian",
  nestedCard: {
    carpet: "E137AP",
    image: E137AP,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E137AP3D,
  },
},
{
  title: "Julian",
  image: D789AP,
  description: "Beige",
  category: "julian",
  nestedCard: {
    carpet: "D789AP",
    image: D789AP,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: D789AP3D,
  },
},
{
  title: "Julian",
  image: E135AW,
  description: "White",
  category: "julian",
  nestedCard: {
    carpet: "E135AW",
    image: E135AW,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E135AW3D,
  },
},{
  title: "Julian",
  image: E135AG,
  description: "Grey",
  category: "julian",
  nestedCard: {
    carpet: "E135AG",
    image: E135AG,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E135AG3D,
  },
},{
  title: "Julian",
  image: E137AW,
  description: "White",
  category: "julian",
  nestedCard: {
    carpet: "E137AW",
    image: E137AW,
    dimmessions: "2.00x3.00",
    dimmessions1: "2.00x2.50",
    dimmessions2: "1.60x2.30",
    model: E137AW3D,
  },
},

  // Decora
  {
    title: "Decora",
    image: D11751G12,
    description: "Grey",
    category: "decora",
    nestedCard: {
      carpet: "11751G12",
      image: D11751G12,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D11751G123D,
    },
  },
  {
    title: "Decora",
    image: D12486,
    description: "Grey",
    category: "decora",
    nestedCard: {
      carpet: "12486G10",
      image: D12486,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D124863D,
    },
  },
  {
    title: "Decora",
    image: D15844,
    description: "Grey",
    category: "decora",
    nestedCard: {
      carpet: "15844G10",
      image: D15844,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D158443D,
    },
  },
  {
    title: "Decora",
    image: D14667,
    description: "Mink",
    category: "decora",
    nestedCard: {
      carpet: "14667V10",
      image: D14667,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D146673D,
    },
  },
  {
    title: "Decora",
    image: D14984,
    description: "Grey",
    category: "decora",
    nestedCard: {
      carpet: "14984G10",
      image: D14984,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D149843D,
    },
  },
  {
    title: "Decora",
    image: D14987,
    description: "Blue",
    category: "decora",
    nestedCard: {
      carpet: "14987U11",
      image: D14987,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D149873D,
    },
  },
  {
    title: "Decora",
    image: D14988,
    description: "Yellow",
    category: "decora",
    nestedCard: {
      carpet: "14988G10",
      image: D14988,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D149883D,
    },
  },
  {
    title: "Decora",
    image: D15844,
    description: "Grey",
    category: "decora",
    nestedCard: {
      carpet: "15844G10",
      image: D15844,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D158443D,
    },
  },
  //Gora
  {
    title: "Gora",
    image: G24130,
    description: "Beige/Beige",
    category: "gora",
    nestedCard: {
      carpet: "24130A",
      image: G24130,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: G241303D,
    },
  },
  {
    title: "Gora",
    image: D12612W17,
    description: "Cream/Cream",
    category: "gora",
    nestedCard: {
      carpet: "12612A",
      image: D12612W17,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: D12612W173D,
    },
  },
  {
    title: "Gora",
    image: G24130A,
    description: "Cream/Cream",
    category: "gora",
    nestedCard: {
      carpet: "24130A",
      image: G24130A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: G24130A3D,
    },
  },
  {
    title: "Gora",
    image: G24131A,
    description: "Beige/Beige",
    category: "gora",
    nestedCard: {
      carpet: "24131A",
      image: G24131A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: G24131A3D,
    },
  },
  {
    title: "Gora",
    image: G24138A,
    description: "Beige/Beige",
    category: "gora",
    nestedCard: {
      carpet: "24138A",
      image: G24138A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: G24138A3D,
    },
  },
  {
    title: "Gora",
    image: G24142A,
    description: "Grey/Grey",
    category: "gora",
    nestedCard: {
      carpet: "24142A",
      image: G24142A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: G24142A3D,
    },
  },
  //Gora Plus
  {
    title: "Gora Plus",
    image: GP24165A,
    description: "Grey/Grey",
    category: "gora plus",
    nestedCard: {
      carpet: "24165A",
      image: GP24165A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: GP24165A3D,
    },
  },
  {
    title: "Gora Plus",
    image: GPl24165A,
    description: "Cream/Cream",
    category: "gora plus",
    nestedCard: {
      carpet: "24165A",
      image: GPl24165A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: GPl24165A3D,
    },
  },
  {
    title: "Gora Plus",
    image: GPlus24165A,
    description: "Beige/Beige",
    category: "gora plus",
    nestedCard: {
      carpet: "24165A",
      image: GPlus24165A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: GPlus24165A3D,
    },
  },
  //Kraft
  {
    title: "Kraft",
    image: K20023,
    description: "Bone/DBeige",
    category: "kraft",
    nestedCard: {
      carpet: "20023D",
      image: K20023,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: K200233D,
    },
  },
  {
    title: "Kraft",
    image: K20443,
    description: "GreyShr/DBeige",
    category: "kraft",
    nestedCard: {
      carpet: "20443A",
      image: K20443,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: K204433D,
    },
  },
  {
    title: "Kraft",
    image: K21691,
    description: "Beige/Beige",
    category: "kraft",
    nestedCard: {
      carpet: "21691A",
      image: K21691,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: K216913D,
    },
  },
  {
    title: "Kraft",
    image: K22816,
    description: "Beige/Beige",
    category: "kraft",
    nestedCard: {
      carpet: "22816A",
      image: K22816,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: K228163D,
    },
  },
  //Pastoral
  {
    title: "Pastoral",
    image: P26551,
    description: "CreamShr/Cream",
    category: "pastoral",
    nestedCard: {
      carpet: "26551A",
      image: P26551,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P265513D,
    },
  },
  {
    title: "Pastoral",
    image: P26702,
    description: "KremShr/White",
    category: "pastoral",
    nestedCard: {
      carpet: "26702A",
      image: P26702,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P267023D,
    },
  },
  {
    title: "Pastoral",
    image: P26704A,
    description: "Cream/KremShr",
    category: "pastoral",
    nestedCard: {
      carpet: "22816A",
      image: P26704A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26704A3D,
    },
  },
  {
    title: "Pastoral",
    image: P26716D,
    description: "GreenShr/Cream",
    category: "pastoral",
    nestedCard: {
      carpet: "26716D",
      image: P26716D,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26716D3D,
    },
  },
  {
    title: "Pastoral",
    image: P26723A,
    description: "KremShr/Cream",
    category: "pastoral",
    nestedCard: {
      carpet: "26723A",
      image: P26723A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26723A3D,
    },
  },
  {
    title: "Pastoral",
    image: P27413A,
    description: "KremShr/BeigeShr",
    category: "pastoral",
    nestedCard: {
      carpet: "27413A",
      image: P27413A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P27413A3D,
    },
  },
  {
    title: "Pastoral",
    image: P26750A,
    description: "KremShr/Cream",
    category: "pastoral",
    nestedCard: {
      carpet: "26750A",
      image: P26750A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26750A3D,
    },
  },
  {
    title: "Pastoral",
    image: P26800A,
    description: "Cream/KremShr",
    category: "pastoral",
    nestedCard: {
      carpet: "26800A",
      image: P26800A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26800A3D,
    },
  },
  {
    title: "Pastoral",
    image: P26818A,
    description: "Cream/KremShr",
    category: "pastoral",
    nestedCard: {
      carpet: "26818A",
      image: P26818A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P26818A3D,
    },
  },
  {
    title: "Pastoral",
    image: P27403A,
    description: "Cream/LBeige",
    category: "pastoral",
    nestedCard: {
      carpet: "27403A",
      image: P27403A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P27403A3D,
    },
  },
  {
    title: "Pastoral",
    image: P27395A,
    description: "KremShr/Cream/Beige",
    category: "pastoral",
    nestedCard: {
      carpet: "27395A",
      image: P27395A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: P27395A3D,
    },
  },
  //Tarz
  {
    title: "Tarz",
    image: T22089A,
    description: "White/Grey",
    category: "tarz",
    nestedCard: {
      carpet: "22089A",
      image: T22089A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: T22089A3D,
    },
  },
  {
    title: "Tarz",
    image: T22196A,
    description: "GreyShr/White",
    category: "tarz",
    nestedCard: {
      carpet: "22196A",
      image: T22196A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: T22196A3D,
    },
  },
  {
    title: "Tarz",
    image: T2211A,
    description: "GreyShr/White",
    category: "tarz",
    nestedCard: {
      carpet: "22211A",
      image: T2211A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: T2211A3D,
    },
  },
  {
    title: "Tarz",
    image: T22175A,
    description: "GreyShr/LightGrey",
    category: "tarz",
    nestedCard: {
      carpet: "22175A",
      image: T22175A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: T22175A3D,
    },
  },
  //Utopia
  {
    title: "Utopia",
    image: U24596A,
    description: "KBej/AKahve",
    category: "utopia",
    nestedCard: {
      carpet: "24596A",
      image: U24596A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: U24596A3D,
    },
  },
  {
    title: "Utopia",
    image: U24605A,
    description: "KBej/Kahve",
    category: "utopia",
    nestedCard: {
      carpet: "24605A",
      image: U24605A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: U24605A3D,
    },
  },
  {
    title: "Utopia",
    image: U24607A,
    description: "KBej/KBej",
    category: "utopia",
    nestedCard: {
      carpet: "24607A",
      image: U24607A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: U24607A3D,
    },
  },
  //Valenica
  {
    title: "Valencia",
    image: V21769B,
    description: "DGreyCkl/GreyShr",
    category: "valencia",
    nestedCard: {
      carpet: "21769B",
      image: V21769B,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V21769B3D,
    },
  },
  {
    title: "Valencia",
    image: V21770A,
    description: "Cream/DBeigeShr",
    category: "valencia",
    nestedCard: {
      carpet: "21770A",
      image: V21770A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V21770A3D,
    },
  },
  {
    title: "Valencia",
    image: V22185B,
    description: "GreyShr/Cream",
    category: "valencia",
    nestedCard: {
      carpet: "22185B",
      image: V22185B,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V22185B3D,
    },
  },
  {
    title: "Valencia",
    image: V22620A,
    description: "LGrey/Cream",
    category: "valencia",
    nestedCard: {
      carpet: "22620A",
      image: V22620A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V22620A3D,
    },
  },
  //mungon
  {
    title: "Valencia",
    image: V26164A,
    description: "BeigeShr/Cream",
    category: "valencia",
    nestedCard: {
      carpet: "26164A",
      image: V26164A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V26164A3D,
    },
  },
  {
    title: "Valencia",
    image: V22622A,
    description: "LightGrey/GreyShr",
    category: "valencia",
    nestedCard: {
      carpet: "22622A",
      image: V22622A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V22622A3D,
    },
  },
  {
    title: "Valencia",
    image: V22621A,
    description: "Cream/LightGrey",
    category: "valencia",
    nestedCard: {
      carpet: "22621A",
      image: V22621A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V22621A3D,
    },
  },
  {
    title: "Valencia",
    image: V22623A,
    description: "LGrey/GreyShr",
    category: "valencia",
    nestedCard: {
      carpet: "22632A",
      image: V22623A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V22623A3D,
    },
  },
  {
    title: "Valencia",
    image: V26030A,
    description: "DarkGreyCkn/GreyShr",
    category: "valencia",
    nestedCard: {
      carpet: "26030A",
      image: V26030A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V26030A3D,
    },
  },
  {
    title: "Valencia",
    image: V26035A,
    description: "Cream/DBeigeShr",
    category: "valencia",
    nestedCard: {
      carpet: "26035A",
      image: V26035A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V26035A3D,
    },
  },
  {
    title: "Valencia",
    image: V26081A,
    description: "DarkGreyCkn/Cream",
    category: "valencia",
    nestedCard: {
      carpet: "26081A",
      image: V26081A,
      dimmessions: "2.00x3.00",
      dimmessions2: "1.60x2.30",
      model: V26081A3D,
    },
  },
  //Venita
  {
    title: "Venita",
    image: V11961G13,
    description: "Grey",
    category: "venita",
    nestedCard: {
      carpet: "11961G13",
      image: V11961G13,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V11961G133D,
    },
  },
  {
    title: "Venita",
    image: V11961V13,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "11961V13",
      image: V11961V13,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V11961V133D,
    },
  },
  {
    title: "Venita",
    image: V11976V13,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "11976V13",
      image: V11976V13,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V11976V133D,
    },
  },
  {
    title: "Venita",
    image: V13596G13,
    description: "Grey",
    category: "venita",
    nestedCard: {
      carpet: "13596G13",
      image: V13596G13,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V13596G133D,
    },
  },
  {
    title: "Venita",
    image: V15260G10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15260V10",
      image: V15260G10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15260G103D,
    },
  },
  {
    title: "Venita",
    image: V15260V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15260V10",
      image: V15260V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15260V103D,
    },
  },
  {
    title: "Venita",
    image: V15261V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15261V10",
      image: V15261V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15261V103D,
    },
  },
  {
    title: "Venita",
    image: V15264K10,
    description: "ColorMulti",
    category: "venita",
    nestedCard: {
      carpet: "15264K10",
      image: V15264K10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15264K103D,
    },
  },
  {
    title: "Venita",
    image: V15265V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15265V10",
      image: V15265V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15265V103D,
    },
  },
  {
    title: "Venita",
    image: V15266G10,
    description: "Grey",
    category: "venita",
    nestedCard: {
      carpet: "15266G10",
      image: V15266G10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15266G103D,
    },
  },
  {
    title: "Venita",
    image: V15267G10,
    description: "Grey",
    category: "venita",
    nestedCard: {
      carpet: "15267G10",
      image: V15267G10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15267G103D,
    },
  },
  {
    title: "Venita",
    image: V15267V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15267V10",
      image: V15267V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15267V103D,
    },
  },
  {
    title: "Venita",
    image: V15267W10,
    description: "White",
    category: "venita",
    nestedCard: {
      carpet: "15267W10",
      image: V15267W10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15267W103D,
    },
  },
  {
    title: "Venita",
    image: V15270V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15270V10",
      image: V15270V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15270V103D,
    },
  },
  {
    title: "Venita",
    image: V15275V10,
    description: "Mink",
    category: "venita",
    nestedCard: {
      carpet: "15275V10",
      image: V15275V10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15275V103D,
    },
  },
  {
    title: "Venita",
    image: V15371G10,
    description: "Grey",
    category: "venita",
    nestedCard: {
      carpet: "15371G10",
      image: V15371G10,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: V15371G103D,
    },
  },
  //Venice
  {
    title: "Venice",
    image: VL9112A,
    description: "Cream",
    category: "venice",
    nestedCard: {
      carpet: "9112A",
      image: VL9112A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VL9112A3D,
    },
  },
  {
    title: "Venice",
    image: VL9142A,
    description: "Grey",
    category: "venice",
    nestedCard: {
      carpet: "9142A",
      image: VL9142A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VL9142A3D,
    },
  },
  {
    title: "Venice",
    image: VL9148B,
    description: "Grey",
    category: "venice",
    nestedCard: {
      carpet: "9148B",
      image: VL9148B,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VL9148B3D,
    },
  },
  {
    title: "Venice",
    image: VL9306A,
    description: "L. Grey",
    category: "venice",
    nestedCard: {
      carpet: "9306A",
      image: VL9306A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VL9306A3D,
    },
  },
  {
    title: "Venice",
    image: VLA785A,
    description: "Grey",
    category: "venice",
    nestedCard: {
      carpet: "A785A",
      image: VLA785A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VLA785A3D,
    },
  },
  {
    title: "Venice",
    image: VLB658A,
    description: "Beige",
    category: "venice",
    nestedCard: {
      carpet: "B658A",
      image: VLB658A,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: VLB658A3D,
    },
  },
  {
    title: "Zara",
    image: DSC05971,
    description: "Cream / Brown",
    category: "zara",
    nestedCard: {
      carpet: "464",
      image: DSC05971,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059713D,
    },
  },
  {
    title: "Zara",
    image: DSC05974,
    description: "Grey / Anthracite",
    category: "zara",
    nestedCard: {
      carpet: "9653",
      image: DSC05974,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059743D,
    },
  },
  {
    title: "Zara",
    image: DSC05976,
    description: "L. Brown / Cream",
    category: "zara",
    nestedCard: {
      carpet: "6276",
      image: DSC05976,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059763D,
    },
  },
  {
    title: "Zara",
    image: DSC05977,
    description: "Grey / Anthracite",
    buttonLink: "path/to/button2",
    category: "zara",
    nestedCard: {
      carpet: "9669",
      image: DSC05977,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059773D,
    },
  },
  {
    title: "Zara",
    image: DSC05978,
    description: "L. Beige / L. Brown",
    category: "zara",
    nestedCard: {
      carpet: "9666",
      image: DSC05978,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059783D,
    },
  },
  {
    title: "Zara",
    image: DSC05979,
    description: "L. Beige / L. Brown",
    category: "zara",
    nestedCard: {
      carpet: "9668",
      image: DSC05979,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059793D,
    },
  },
  {
    title: "Zara",
    image: DSC05980,
    description: "Brown / Cream",
    category: "zara",
    nestedCard: {
      carpet: "576",
      image: DSC05980,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059803D,
    },
  },
  {
    title: "Zara",
    image: DSC05981,
    description: "Cream / Brown",
    category: "zara",
    nestedCard: {
      carpet: "579",
      image: DSC05981,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059813D,
    },
  },
  {
    title: "Zara",
    image: DSC05982,
    description: "Grey / Cream",
    category: "zara",
    nestedCard: {
      carpet: "6116",
      image: DSC05982,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059823D,
    },
  },
  {
    title: "Zara",
    image: DSC05984,
    description: "L. Beige/L. Brown",
    category: "zara",
    nestedCard: {
      carpet: "9653",
      image: DSC05984,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059843D,
    },
  },
  //missing 986 or write wrong in the pic
  {
    title: "Zara",
    image: DSC05986,
    description: "Grey / Cream",
    category: "zara",
    nestedCard: {
      carpet: "1663",
      image: DSC05986,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059863D,
    },
  },
  //missing 989
  {
    title: "Zara",
    image: DSC05989,
    description: "Cream / Anthracite",
    category: "zara",
    nestedCard: {
      carpet: "840",
      image: DSC05989,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059893D,
    },
  },
  //missing 990
  {
    title: "Zara",
    image: DSC05990,
    description: "Cream / Brown",
    category: "zara",
    nestedCard: {
      carpet: "466",
      image: DSC05990,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059903D,
    },
  },
  {
    title: "Zara",
    image: DSC05993,
    description: "L.Beige / Blue",
    category: "zara",
    nestedCard: {
      carpet: "966",
      image: DSC05993,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059933D,
    },
  },
  {
    title: "Zara",
    image: DSC05994,
    description: "Cream / Anthracite",
    category: "zara",
    nestedCard: {
      carpet: "465",
      image: DSC05994,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059943D,
    },
  },
  // 995 missing
  {
    title: "Zara",
    image: DSC05995,
    description: "L.Beige / Beige",
    category: "zara",
    nestedCard: {
      carpet: "6613",
      image: DSC05995,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059953D,
    },
  },
  {
    title: "Zara",
    image: DSC05996,
    description: "Cream/L. Beige",
    category: "zara",
    nestedCard: {
      carpet: "7096",
      image: DSC05996,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059963D,
    },
  },
  //997 missing
  {
    title: "Zara",
    image: DSC05997,
    description: "L. Brown/Brown Poly",
    category: "zara",
    nestedCard: {
      carpet: "9256",
      image: DSC05997,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059973D,
    },
  },
  {
    title: "Zara",
    image: DSC05999,
    description: "L. Beige/L. Brown",
    category: "zara",
    nestedCard: {
      carpet: "8347",
      image: DSC05999,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC059993D,
    },
  },

  //Lisa Cards
  {
    title: "Lisa",
    image: DSC06061,
    description: "L.Grey / Ivory",
    category: "lisa",
    nestedCard: {
      carpet: "9732",
      image: DSC06061,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060613D,
    },
  },
  {
    title: "Lisa",
    image: DSC06062,
    description: "L.Grey / Blue",
    category: "lisa",
    nestedCard: {
      carpet: "2115",
      image: DSC06062,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060623D,
    },
  },
  {
    title: "Lisa",
    image: DSC06063,
    description: "Anthracite/Blue",
    category: "lisa",
    nestedCard: {
      carpet: "2089",
      image: DSC06063,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060633D,
    },
  },
  {
    title: "Lisa",
    image: DSC06064,
    description: "L.Grey / Ivory",
    category: "lisa",
    nestedCard: {
      carpet: "2410",
      image: DSC06064,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060643D,
    },
  },
  {
    title: "Lisa",
    image: DSC06065,
    description: "Ivory / Anthracite",
    category: "lisa",
    nestedCard: {
      carpet: "620",
      image: DSC06065,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060653D,
    },
  },
  {
    title: "Lisa",
    image: DSC06066,
    description: "Anthracite/Mustard",
    category: "lisa",
    nestedCard: {
      carpet: "2415",
      image: DSC06066,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060663D,
    },
  },
  {
    title: "Lisa",
    image: DSC06067,
    description: "Ivory / Mustard",
    category: "lisa",
    nestedCard: {
      carpet: "2084",
      image: DSC06067,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060673D,
    },
  },
  {
    title: "Lisa",
    image: DSC06068,
    description: "Ivory/Blue",
    category: "lisa",
    nestedCard: {
      carpet: "862",
      image: DSC06068,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060683D,
    },
  },
  {
    title: "Lisa",
    image: DSC06069,
    description: "Anthracite / Ivory",
    category: "lisa",
    nestedCard: {
      carpet: "878",
      image: DSC06069,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060693D,
    },
  },
  {
    title: "Lisa",
    image: DSC06070,
    description: "Anthracite / Ivory",
    category: "lisa",
    nestedCard: {
      carpet: "2393",
      image: DSC06070,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060703D,
    },
  },
  {
    title: "Lisa",
    image: DSC06072,
    description: "Ivory / L. Grey",
    category: "lisa",
    nestedCard: {
      carpet: "1404",
      image: DSC06072,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060723D,
    },
  },
  {
    title: "Lisa",
    image: DSC06073,
    description: "Ivory / Anthracite",
    category: "lisa",
    nestedCard: {
      carpet: "628",
      image: DSC06073,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060733D,
    },
  },
  {
    title: "Lisa",
    image: DSC06074,
    description: "Ivory / Mustard",
    category: "lisa",
    nestedCard: {
      carpet: "2395",
      image: DSC06074,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060743D,
    },
  },
  {
    title: "Lisa",
    image: DSC06075,
    description: "L.Grey / Blue",
    category: "lisa",
    nestedCard: {
      carpet: "972",
      image: DSC06075,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060753D,
    },
  },
  //Ares Cards
  {
    title: "Ares",
    image: DSC06013,
    description: "Ivory / D.Beige",
    category: "ares",
    nestedCard: {
      carpet: "39",
      image: DSC06013,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060133D,
    },
  },
  {
    title: "Ares",
    image: DSC06014,
    description: "Ivory / L. Grey",
    category: "ares",
    nestedCard: {
      carpet: "1876",
      image: DSC06014,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060143D,
    },
  },
  {
    title: "Ares",
    image: DSC06016,
    description: "L.Grey / L.Grey",
    category: "ares",
    nestedCard: {
      carpet: "111",
      image: DSC06016,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060163D,
    },
  },
  {
    title: "Ares",
    image: DSC06017,
    description: "Ivory / Beige",
    category: "ares",
    nestedCard: {
      carpet: "1552",
      image: DSC06017,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060173D,
    },
  },
  {
    title: "Ares",
    image: DSC06018,
    description: "L.Grey / L.Grey",
    category: "ares",
    nestedCard: {
      carpet: "1590",
      image: DSC06018,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060183D,
    },
  },
  {
    title: "Ares",
    image: DSC06019,
    description: "Beige / Beige",
    category: "ares",
    nestedCard: {
      carpet: "1590",
      image: DSC06019,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060193D,
    },
  },
  {
    title: "Ares",
    image: DSC06020,
    description: "Ivory / Beige",
    category: "ares",
    nestedCard: {
      carpet: "1599",
      image: DSC06020,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060203D,
    },
  },
  {
    title: "Ares",
    image: DSC06021,
    description: "Beige / D. Beige",
    category: "ares",
    nestedCard: {
      carpet: "9993",
      image: DSC06021,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060213D,
    },
  },
  {
    title: "Ares",
    image: DSC06022,
    description: "L.Grey / D.Grey",
    category: "ares",
    nestedCard: {
      carpet: "050",
      image: DSC06022,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060223D,
    },
  },
  //Dizayn Cards
  {
    title: "Dizayn",
    image: DSC06024,
    description: "Brown / D.Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "8376",
      image: DSC06024,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060243D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06025,
    description: "Beige / Green",
    category: "dizayn",
    nestedCard: {
      carpet: "034",
      image: DSC06025,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060253D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06027,
    description: "Gri",
    category: "dizayn",
    nestedCard: {
      carpet: "6167",
      image: DSC06027,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060273D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06028,
    description: "Ivory / D.Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "1568",
      image: DSC06028,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060283D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06029,
    description: "Ivory / L. Grey",
    category: "dizayn",
    nestedCard: {
      carpet: "8803",
      image: DSC06029,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060293D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06030,
    description: "C. Beige / D. Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "2332",
      image: DSC06030,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060303D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06031,
    description: "Beige / D. Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "7097",
      image: DSC06031,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060313D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06032,
    description: "Beige / Ivory",
    category: "dizayn",
    nestedCard: {
      carpet: "9996",
      image: DSC06032,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060323D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06033,
    description: "C.Grey / L.Grey",
    category: "dizayn",
    nestedCard: {
      carpet: "2332",
      image: DSC06033,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060333D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06034,
    description: "L.Grey / Ivory",
    category: "dizayn",
    nestedCard: {
      carpet: "7058",
      image: DSC06034,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060343D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06035,
    description: "Ivory / Ivory",
    category: "dizayn",
    nestedCard: {
      carpet: "9986",
      image: DSC06035,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060353D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06036,
    description: "Ivory / L. Grey",
    category: "dizayn",
    nestedCard: {
      carpet: "987",
      image: DSC06036,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060363D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06037,
    description: "Ivory / L. Grey",
    category: "dizayn",
    nestedCard: {
      carpet: "8801",
      image: DSC06037,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060373D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06038,
    description: "C. Beige / Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "2192",
      image: DSC06038,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060383D,
    },
  },
  {
    title: "Dizayn",
    image: DSC06039,
    description: "Beige",
    category: "dizayn",
    nestedCard: {
      carpet: "8119",
      image: DSC06039,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060393D,
    },
  },

  //Vals Cards
  {
    title: "Vals",
    image: DSC06040,
    description: "Ivory / L. Grey",
    category: "vals",
    nestedCard: {
      carpet: "9993",
      image: DSC06040,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060403D,
    },
  },
  {
    title: "Vals",
    image: DSC06041,
    description: "Ivory / Beige",
    category: "vals",
    nestedCard: {
      carpet: "0115",
      image: DSC06041,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060413D,
    },
  },
  {
    title: "Vals",
    image: DSC06042,
    description: "Beige / Ivory",
    category: "vals",
    nestedCard: {
      carpet: "8267",
      image: DSC06042,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060423D,
    },
  },
  {
    title: "Vals",
    image: DSC06044,
    description: "Ivory / Ivory",
    category: "vals",
    nestedCard: {
      carpet: "0952",
      image: DSC06044,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060443D,
    },
  },
  {
    title: "Vals",
    image: DSC06045,
    description: "Ivory / L. Grey",
    category: "vals",
    nestedCard: {
      carpet: "1735",
      image: DSC06045,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060453D,
    },
  },

  //Lowa Cards
  {
    title: "Lowa",
    image: DSC06000,
    description: "Ivory / Mustard",
    category: "lowa",
    nestedCard: {
      carpet: "1568",
      image: DSC06000,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060003D,
    },
  },
  //missing 6002
  {
    title: "Lowa",
    image: DSC06002,
    description: "Ivory / Mustard",
    category: "lowa",
    nestedCard: {
      carpet: "0610",
      image: DSC06002,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060023D,
    },
  },
  {
    title: "Lowa",
    image: DSC06003,
    description: "Ivory / Mustard",
    category: "lowa",
    nestedCard: {
      carpet: "1178",
      image: DSC06003,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060033D,
    },
  },
  //missing 6004
  {
    title: "Lowa",
    image: DSC06004,
    description: "Ivory / Mustard",
    category: "lowa",
    nestedCard: {
      carpet: "3971",
      image: DSC06004,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060043D,
    },
  },
  {
    title: "Lowa",
    image: DSC06005,
    description: "Anthracite / L. Grey",
    category: "lowa",
    nestedCard: {
      carpet: "3582",
      image: DSC06005,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060053D,
    },
  },
  {
    title: "Lowa",
    image: DSC06007,
    description: "Ivory / Tile",
    category: "lowa",
    nestedCard: {
      carpet: "2197",
      image: DSC06007,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060073D,
    },
  },
  {
    title: "Lowa",
    image: DSC06008,
    description: "C.Grey / L.Grey",
    category: "lowa",
    nestedCard: {
      carpet: "3981",
      image: DSC06008,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060083D,
    },
  },
  {
    title: "Lowa",
    image: DSC06009,
    description: "Cream / Mustard",
    category: "lowa",
    nestedCard: {
      carpet: "3713",
      image: DSC06009,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060093D,
    },
  },
  {
    title: "Lowa",
    image: DSC06010,
    description: "Ivory / L. Grey",
    category: "lowa",
    nestedCard: {
      carpet: "3979",
      image: DSC06010,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060103D,
    },
  },
  {
    title: "Lowa",
    image: DSC06011,
    description: "Cream / Anthracite",
    category: "lowa",
    nestedCard: {
      carpet: "4022",
      image: DSC06011,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060113D,
    },
  },
  //Opera Cards
  {
    title: "Opera",
    image: DSC06076,
    description: "Ivory / Brown Poly",
    category: "opera",
    nestedCard: {
      carpet: "1824",
      image: DSC06076,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060763D,
    },
  },
  {
    title: "Opera",
    image: DSC06077,
    description: "Ivory / Blue Poly",
    category: "opera",
    nestedCard: {
      carpet: "2170",
      image: DSC06077,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060773D,
    },
  },
  {
    title: "Opera",
    image: DSC06078,
    description: "L. Grey / Grey Poly",
    category: "opera",
    nestedCard: {
      carpet: "2150",
      image: DSC06078,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060783D,
    },
  },
  {
    title: "Opera",
    image: DSC06079,
    description: "D. Grey / Black",
    category: "opera",
    nestedCard: {
      carpet: "9789",
      image: DSC06079,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060793D,
    },
  },
  {
    title: "Opera",
    image: DSC06080,
    description: "Brown Poly / D. Beige",
    category: "opera",
    nestedCard: {
      carpet: "0240",
      image: DSC06080,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060803D,
    },
  },
  {
    title: "Opera",
    image: DSC06081,
    description: "Ivory / Black",
    category: "opera",
    nestedCard: {
      carpet: "1018",
      image: DSC06081,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060813D,
    },
  },
  {
    title: "Opera",
    image: DSC06082,
    description: "L. Grey / White",
    category: "opera",
    nestedCard: {
      carpet: "9541",
      image: DSC06082,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060823D,
    },
  },
  {
    title: "Opera",
    image: DSC06083,
    description: "Ivory / Black",
    category: "opera",
    nestedCard: {
      carpet: "1012",
      image: DSC06083,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060833D,
    },
  },
  {
    title: "Opera",
    image: DSC06084,
    description: "Ivory / Black",
    category: "opera",
    nestedCard: {
      carpet: "1011",
      image: DSC06084,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060843D,
    },
  },
  {
    title: "Opera",
    image: DSC06085,
    description: "Ivory / Blue Poly",
    category: "opera",
    nestedCard: {
      carpet: "1828",
      image: DSC06085,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060853D,
    },
  },
  {
    title: "Opera",
    image: DSC06086,
    description: "L.Beige / L.Grey",
    category: "opera",
    nestedCard: {
      carpet: "8502",
      image: DSC06086,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060863D,
    },
  },
  {
    title: "Opera",
    image: DSC06087,
    description: "L.Grey / Ivory",
    category: "opera",
    nestedCard: {
      carpet: "9782",
      image: DSC06087,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060873D,
    },
  },
  {
    title: "Opera",
    image: DSC06088,
    description: "Ivory / L. Beige",
    category: "opera",
    nestedCard: {
      carpet: "1821",
      image: DSC06088,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060883D,
    },
  },
  {
    title: "Opera",
    image: DSC06089,
    description: "Ivory / L.Beige",
    category: "opera",
    nestedCard: {
      carpet: "0004",
      image: DSC06089,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060893D,
    },
  },
  {
    title: "Opera",
    image: DSC06090,
    description: "L. Beige / Ivory",
    category: "opera",
    nestedCard: {
      carpet: "0003",
      image: DSC06090,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060903D,
    },
  },
  {
    title: "Opera",
    image: DSC06091,
    description: "Ivory / L.Beige",
    category: "opera",
    nestedCard: {
      carpet: "0002",
      image: DSC06091,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060913D,
    },
  },

  {
    title: "Opera",
    image: DSC06092,
    description: "Ivory / Brown Poly",
    category: "opera",
    nestedCard: {
      carpet: "1819",
      image: DSC06092,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060923D,
    },
  },
  {
    title: "Opera",
    image: DSC06093,
    description: "L.Grey / L.Beige",
    category: "opera",
    nestedCard: {
      carpet: "8502",
      image: DSC06093,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060933D,
    },
  },
  {
    title: "Opera",
    image: DSC06094,
    description: "Ivory /Grey Poly",
    category: "opera",
    nestedCard: {
      carpet: "1824",
      image: DSC06094,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060943D,
    },
  },
  {
    title: "Opera",
    image: DSC06095,
    description: "Ivory /L.Beige",
    category: "opera",
    nestedCard: {
      carpet: "0003",
      image: DSC06095,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060953D,
    },
  },
  {
    title: "Opera",
    image: DSC06096,
    description: "Ivory / Gold",
    category: "opera",
    nestedCard: {
      carpet: "1820",
      image: DSC06096,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060963D,
    },
  },
  //97 missing
  {
    title: "Opera",
    image: DSC06097,
    description: "L.Grey / D.Grey",
    category: "opera",
    nestedCard: {
      carpet: "0171",
      image: DSC06097,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060973D,
    },
  },
  //98 missing
  {
    title: "Opera",
    image: DSC06098,
    description: "D.Grey / White",
    category: "opera",
    nestedCard: {
      carpet: "1217",
      image: DSC06098,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060983D,
    },
  },
  {
    title: "Opera",
    image: DSC06099,
    description: "Light Grey / Grey Poly",
    category: "opera",
    nestedCard: {
      carpet: "0003",
      image: DSC06099,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC060993D,
    },
  },
  //Springs Cards
  {
    title: "Springs",
    image: DSC06101,
    description: "D.Beige / D.Beige",
    category: "springs",
    nestedCard: {
      carpet: "2508",
      image: DSC06101,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061013D,
    },
  },
  {
    title: "Springs",
    image: DSC06102,
    description: "L.Blue / L.Grey",
    category: "springs",
    nestedCard: {
      carpet: "3236",
      image: DSC06102,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061023D,
    },
  },

  {
    title: "Springs",
    image: DSC06103,
    description: "D. Beige / D. Beige",
    category: "springs",
    nestedCard: {
      carpet: "2504",
      image: DSC06103,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061033D,
    },
  },

  {
    title: "Springs",
    image: DSC06104,
    description: "D.Beige / L.Beige",
    category: "springs",
    nestedCard: {
      carpet: "3227",
      image: DSC06104,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061043D,
    },
  },
  {
    title: "Springs",
    image: DSC06105,
    description: "Anthracite / Beige",
    category: "springs",
    nestedCard: {
      carpet: "2178",
      image: DSC06105,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061053D,
    },
  },
  {
    title: "Springs",
    image: DSC06106,
    description: "L.Grey / Anthracite",
    category: "springs",
    nestedCard: {
      carpet: "2498",
      image: DSC06106,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061063D,
    },
  },
  //Meredith Cards
  {
    title: "Meredith",
    image: DSC06107,
    description: "L.Beige / L.Brown",
    category: "meredith",
    nestedCard: {
      carpet: "9634",
      image: DSC06107,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061073D,
    },
  },
  {
    title: "Meredith",
    image: DSC06108,
    description: "Beige / Brown",
    category: "meredith",
    nestedCard: {
      carpet: "4008",
      image: DSC06108,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061083D,
    },
  },
  {
    title: "Meredith",
    image: DSC06109,
    description: "C.Gri / K.Gri",
    category: "meredith",
    nestedCard: {
      carpet: "1140",
      image: DSC06109,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061093D,
    },
  },
  {
    title: "Meredith",
    image: DSC06110,
    description: "L.Beige/ Cream",
    category: "meredith",
    nestedCard: {
      carpet: "1013",
      image: DSC06110,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061103D,
    },
  },
  {
    title: "Meredith",
    image: DSC06111,
    description: "Gri / Brown",
    category: "meredith",
    nestedCard: {
      carpet: "3497",
      image: DSC06111,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061113D,
    },
  },
  {
    title: "Meredith",
    image: DSC06112,
    description: "Gri / Gri",
    category: "meredith",
    nestedCard: {
      carpet: "1075",
      image: DSC06112,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061123D,
    },
  },

  {
    title: "Meredith",
    image: DSC06113,
    description: "Gri / Beige",
    category: "meredith",
    nestedCard: {
      carpet: "4004",
      image: DSC06113,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061133D,
    },
  },
  {
    title: "Meredith",
    image: DSC06114,
    description: "Beige / Brown",
    category: "meredith",
    nestedCard: {
      carpet: "3488",
      image: DSC06114,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061143D,
    },
  },
  {
    title: "Meredith",
    image: DSC06115,
    description: "L.Beige / Beige",
    category: "meredith",
    nestedCard: {
      carpet: "4019",
      image: DSC06115,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061153D,
    },
  },

  {
    title: "Meredith",
    image: DSC06116,
    description: "Beige / Brown",
    category: "meredith",
    nestedCard: {
      carpet: "9449",
      image: DSC06116,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061163D,
    },
  },
  {
    title: "Meredith",
    image: DSC06117,
    description: "Gri / Gri",
    category: "meredith",
    nestedCard: {
      carpet: "3482",
      image: DSC06117,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061173D,
    },
  },
  {
    title: "Meredith",
    image: DSC06118,
    description: "L.Beige/ Brown",
    category: "meredith",
    nestedCard: {
      carpet: "3485",
      image: DSC06118,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061183D,
    },
  },
  {
    title: "Meredith",
    image: DSC06122,
    description: "Beige / Brown",
    category: "meredith",
    nestedCard: {
      carpet: "4004",
      image: DSC06122,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061223D,
    },
  },
  {
    title: "Meredith",
    image: DSC06119,
    description: "Gri / K.Gri",
    category: "meredith",
    nestedCard: {
      carpet: "3488",
      image: DSC06119,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061193D,
    },
  },
  {
    title: "Meredith",
    image: DSC06121,
    description: "C.Beige / Beige",
    category: "meredith",
    nestedCard: {
      carpet: "1140",
      image: DSC06121,
      dimmessions: "2.00x3.00",
      dimmessions1: "2.00x2.50",
      dimmessions2: "1.60x2.30",
      model: DSC061213D,
    },
  },
];

export default cardsData;
