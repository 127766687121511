import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import arrow from "./media/arrow.svg";
import HeroB from "./media/Rooms/HeroB.webp";
import Lisa from "./media/Rooms/Lisa.webp";
import Decora from "./media/Rooms/Decora.webp";
import Gora from "./media/Rooms/Gora.webp";
import Tarz from "./media/Rooms/Tarz.webp";
import pastoral from "./media/Rooms/pastoral.webp";

import D12486 from "./media/Decora/D12486.webp";
function Hero() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        data-aos="fade-right"
        data-aos-duration="2000"
        className="sm:hidden block sm:pl-44 pl-0 sm:pr-44 pr-0">
        <h1 className="  ml-4 text-5xl  mt-10 pr-24 text-white">
          Experiencing beauty
        </h1>
        <div className=" grid grid-flow-col">
          <div className="col-span-1 text-bold text-sm mt-14 ">
            <div className="border-solid bg-buttons ml-4 ">
              <h1 className=" text-white text-left ml-4  pt-5 pb-5 mr-2">
                New <br /> Collection
              </h1>
            </div>
            <div className="border-solid ml-4 mt-4">
              <div className="">
                <img src={D12486} className=" h-32 row-span-1 " alt="Decora" />
              </div>
            </div>
          </div>
          <div data-aos="fade-down" data-aos-duration="2000" className="">
            <div className=" rounded-xl mt-14 ml-4">
              <img src={HeroB} alt="Hero" className=" h-36 w-auto" />
            </div>
            <div className=" grid grid-cols-2">
              <img src={Lisa} alt="Lisa" className=" h-24 w-40 mt-4 ml-4 " />
              <div className=" border-solid bg-buttons ml-8 mr-8 mt-4 pt-5 pb-5 mb-10 ">
                <h1 className=" text-white text-sm text-left ml-2 ">
                  Choose <br />
                  your style
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className=" ml-16">
          <div className="rounded-xl  bg-buttons h-12 w-12 inline-block ml-5"></div>
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5"></div>
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5"></div>
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5"></div>
        </div>
        <div className=" ml-14 mb-12 -m-12">
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5">
            <img
              src={Decora}
              alt="Decora"
              className=" aspect-square rounded-xl"
            />
          </div>
          <div className="rounded-xl  bg-buttons    h-12 w-12  inline-block ml-5">
            <img src={Gora} alt="Gora" className=" aspect-square rounded-xl" />
          </div>
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5">
            <img
              src={pastoral}
              alt="pastoral"
              className=" aspect-square rounded-xl"
            />
          </div>
          <div className="rounded-xl  bg-buttons h-12 w-12  inline-block ml-5">
            <img src={Tarz} alt="Tarz" className=" aspect-square rounded-xl" />
          </div>
        </div>
      </div>
      {/* Desktop version */}
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className="sm:block hidden sm:pl-44 pl-0 sm:pr-44 pr-0 mt-8">
        <div className=" grid grid-cols-2 bg-nav ">
          <div className="">
            <div className="grid grid-flow-col">
              <h1 className=" text-white  text-6xl tracking-wider leading-normal mt-20">
                Experiencing beauty...
              </h1>
              <div className="  text-base ml-16 mt-14  ">
                <div className="border-solid bg-buttons">
                  <h1 className=" text-white pl-8 pt-8 pr-16  pb-8  ">
                    New <br /> Collection
                  </h1>
                </div>
                <div className=" ">
                  <img src={D12486} alt="Decora" className="mt-6" />
                </div>
              </div>
            </div>
            <div className=" -mt-20">
              <div className=" text-white">
                <a href="/catalog">
                  <button class="bg-buttons   text-white hover:scale-105   py-2 px-10 ">
                    Catalog
                  </button>
                </a>
                <h1 className=" text-white   inline-block ml-6 ">Our Story</h1>
                <div className="rounded-full  bg-buttons h-7 w-7  inline-block ml-5 hover:scale-110">
                  <a href="/about" className="">
                    <img
                      src={arrow}
                      alt="next"
                      className=" ml-1  w-6 h-6 inline-block"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className=" -z-50 ml-8 mt-32 w-96 border-solid border-buttons border-t-2"></div>
            <div className=" -mt-10 ml-14">
              <div className="rounded-xl  bg-buttons h-20 w-20 inline-block ml-5"></div>
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block ml-5"></div>
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block ml-5"></div>
              <div className="rounded-xl  bg-buttons h-20 w-20 inline-block ml-5"></div>
            </div>
            <div className=" ml-16 mb-12 -m-20">
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block">
                <img
                  src={Decora}
                  alt="Decora"
                  className=" aspect-square rounded-xl"
                />
              </div>
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block ml-5">
                <img
                  src={Gora}
                  alt="Gora"
                  className=" aspect-square rounded-xl"
                />
              </div>
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block ml-5">
                <img
                  src={pastoral}
                  alt="pastoral"
                  className=" aspect-square rounded-xl"
                />
              </div>
              <div className="rounded-xl  bg-buttons h-20 w-20  inline-block ml-5">
                <img
                  src={Tarz}
                  alt="Tarz"
                  className=" aspect-square rounded-xl"
                />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="2000"
            className="col-span-1 mt-14 ml-20">
            <div className=" rounded-xl">
              <img src={HeroB} alt="hero" className=" h-72 w-auto" />
            </div>
            <div className="grid grid-cols-2">
              <div className=" col-span-1 border-solid rounded-xl mt-6">
                <img
                  src={Lisa}
                  alt="lisa"
                  className=" ml-4 h-52 "
                />
                 </div>
                <div className=" shadow-sm  mt-14 mb-14 ml-8 border-solid bg-buttons   pt-10 pb-10  inline-block">
                  <h1 className=" text-white  pr-10 pl-10  ">
                    Choose <br />
                    your style
                  </h1>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
