import vjosa2 from "./media/vjosa1.jpeg";
import { useEffect } from "react";
import AOS from "aos";
function Acard() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className=" sm:pl-48 pl-0 mb-20">
      <h1 className=" mt-20 text-3xl text-white sm:text-left text-center  first-letter">
        About Us
      </h1>
      <div className=" grid sm:grid-cols-3 grid-cols-0">
        <div className=" border-b-4 mt-4 sm:mr-64 mr-28 sm:ml-0 ml-28"></div>
      </div>
      <div data-aos="fade-up" data-aos-duration="2000" className=" mt-14">
        <div class="flex flex-col items-center bg-footer  shadow sm:flex-row sm:mr-48 mr-14 sm:ml-0 ml-14">
          <img
            class="object-cover     md:w-80 w-full "
            src={vjosa2}
            alt="Vjosa"
          />
          <div class="flex flex-col justify-between p-4 leading-normal ml-4 mr-4">
            <h5 class="sm:mt-0 mt-4 mb-4 text-2xl md:text-3xl  tracking-tight text-white dark:text-white">
              Who are we?
            </h5>
            <p class="mb-3  text-white md:text-lg">
              Established in 1991, our company is specialized in carpets, wall
              to wall and linoleum products. With more than three decades of
              experience in the market, Vjosa is a leader that provides a wide
              range of colors and qualities and latest designs. Our aim is to
              always improve and give our loyal customers the best service in
              the market.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Acard;
