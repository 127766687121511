import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Collection from "./Collection";
import Footer from "./Footer";

function Home() {
  return (
    <div className="">
      <Header />
      <Navbar />
      <Hero />
      <Collection />
      <Footer />
    </div>
  );
}
export default Home;
