import React, { lazy, Suspense } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import LoadingScreen from "./LoadingScreen";
import Footer from "./Footer";
import { useParams } from "react-router-dom";

const Ccards = lazy(() => import("./Ccards"));

function Catalog() {
  const params = useParams();

  return (
    <div className="font-default">
      {" "}
      <Header />
      <Navbar />
      <Suspense fallback={<LoadingScreen />}>
        <Ccards category={params.category} id={params.id} />
      </Suspense>
      <Footer />
    </div>
  );
}

export default Catalog;
