import React, { Suspense } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import LoadingScreen from "./LoadingScreen";
import Footer from "./Footer";
import Gallery from "./Gallery";

function Photos() {
  return (
    <div className="">
      <Header />
      <Navbar />
      <Suspense fallback={<LoadingScreen />}>
        <Gallery />
      </Suspense>
      <Footer />
    </div>
  );
}
export default Photos;
