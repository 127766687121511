import { useEffect } from "react";
import Anett from "./media/Rooms/Anett.webp";
import Angel from "./media/Rooms/angel.webp";
import Viera from "./media/Rooms/viera.webp";
import Fiara from "./media/Rooms/fiara.webp";
import Amber from "./media/Rooms/amber.webp";
import Julian from "./media/Rooms/julian.webp";

import venice from "./media/Rooms/venice.webp";
import valencia from "./media/Rooms/valencia.webp";
import venita from "./media/Rooms/venita.webp";
import AOS from "aos";
import "aos/dist/aos.css";
function Collection() {
  function handleCardClick(path) {
    localStorage.setItem("selectedCardPath", path);
  }
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className=" mt-20 text-3xl text-white text-center ">
        Catalog
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3">
        <div></div>
        <div className="text-center col-span-1 border-b-4 mt-4 mb-12 sm:mr-40 mr-28  sm:ml-40 ml-28 "></div>
      </div>
      <div className=" sm:grid grid-cols-3  sm:pl-44 sm:pr-44  ">
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          className="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105"
          onClick={() => handleCardClick("/catalog/anett")}>
          <a href="/catalog/anett">
            <img className=" sm:h-auto" src={Anett} alt="" />
          </a>
          <div className="p-5">
            <a href="/catalog/anett">
              <h5 className="mb-2 text-2xl text-center text-white   tracking-tight ">
                Anett
              </h5>
            </a>
          </div>
          <a href="/catalog/anett">
            <div
              class="sm:block hidden  md:absolute md:inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/anett")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Anett
              </h5>
            </div>
          </a>
        </div>
        {/* Angel Card */}
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/angel")}>
          <a href="/catalog/angel">
            <img class=" sm:h-auto" src={Angel} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/angel">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
                Angel
              </h5>
            </a>
          </div>
          <a href="/catalog/angel">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/angel")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Angel
              </h5>
            </div>
          </a>
        </div>
        {/* Viera Ultra Card */}
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/viera")}>
          <a href="/catalog/viera">
            <img class=" sm:h-auto" src={Viera} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/viera">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
              Viera Ultra
              </h5>
            </a>
          </div>
          <a href="/catalog/viera">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/viera")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
              Viera Ultra
              </h5>
            </div>
          </a>
        </div>
        {/* Fiara Card */}
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/fiara")}>
          <a href="/catalog/fiara">
            <img class=" sm:h-auto" src={Fiara} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/fiara">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
                Fiara
              </h5>
            </a>
          </div>
          <a href="/catalog/fiara">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/fiara")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Fiara
              </h5>
            </div>
          </a>
        </div>
        {/* Amber Card */}
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/amber")}>
          <a href="/catalog/amber">
            <img class=" sm:h-auto" src={Amber} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/amber">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
              Amber
              </h5>
            </a>
          </div>
          <a href="/catalog/amber">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/amber")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
              Amber
              </h5>
            </div>
          </a>
        </div>
        {/* Julian Card */}
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/julian")}>
          <a href="/catalog/utopia">
            <img class=" sm:h-auto" src={Julian} alt="julian" />
          </a>
          <div class="p-5">
            <a href="/catalog/julian">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
              Julian
              </h5>
            </a>
          </div>
          <a href="/catalog/julian">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/julian")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
              Julian
              </h5>
            </div>
          </a>
        </div>
        {/* Valencia Card */}
        <div
          data-aos="fade-right"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/valencia")}>
          <a href="/catalog/valencia">
            <img class=" sm:h-auto" src={valencia} alt="Valencia" />
          </a>
          <div class="p-5">
            <a href="/catalog/valencia">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
                Valencia
              </h5>
            </a>
          </div>
          <a href="/catalog/valencia">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/valencia")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Valencia
              </h5>
            </div>
          </a>
        </div>
        {/* Venice Card */}
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/venice")}>
          <a href="/catalog/venice">
            <img class="sm:h-auto" src={venice} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/venice">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
                Venice
              </h5>
            </a>
          </div>
          <a href="/catalog/venice">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/venice")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Venice
              </h5>
            </div>
          </a>
        </div>
        {/* Venita Card */}
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          class="md:relative sm:max-w-sm  max-w-fit bg-buttons border-solid shadow-xl sm:ml-4 ml-20 sm:mr-0 mr-20 sm:mb-12 mb-16  sm:hover:scale-105 "
          onClick={() => handleCardClick("/catalog/venita")}>
          <a href="/catalog/venita">
            <img class=" sm:h-auto" src={venita} alt="" />
          </a>
          <div class="p-5">
            <a href="/catalog/venita">
              <h5 class="mb-2 text-2xl text-center text-white   tracking-tight ">
                Venita
              </h5>
            </a>
          </div>
          <a href="/catalog/venita">
            <div
              class="sm:block hidden  absolute inset-0 bg-opacity-100  bg-buttons   opacity-0 transition-opacity duration-300 hover:opacity-100"
              onClick={() => handleCardClick("/catalog/venita")}>
              <h5 class=" text-4xl text-center text-white mt-36   tracking-tight ">
                Venita
              </h5>
            </div>
          </a>
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1350"
        className=" flex justify-center ">
        <a
          href="/catalog"
          className="bg-footer rounded-full shadow-2xl  text-white  py-4 px-10 mt-12 justify-items-center sm:mb-20 mb-12  hover:scale-105 ">
          GO TO CATALOG
        </a>
      </div>
    </>
  );
}

export default Collection;
