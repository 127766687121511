import { useState, useEffect } from "react";
import Decora from "./media/Rooms/Decora.webp";
import utopia from "./media/Rooms/utopia.webp";
import venice from "./media/Rooms/venice.webp";
import valencia from "./media/Rooms/valencia.webp";
import kraft from "./media/Rooms/kraft.webp";
import Gora from "./media/Rooms/Gora.webp";
import Tarz from "./media/Rooms/Tarz.webp";
import pastoral from "./media/Rooms/pastoral.webp";
import venita from "./media/Rooms/venita.webp";
import AOS from "aos";
import "aos/dist/aos.css";
function Gallery() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const images = [
    Decora,
    utopia,
    venice,
    valencia,
    kraft,
    Gora,
    venita,
    Tarz,
    pastoral,
  ];

  const handleOpenImage = (index) => {
    setIsOpen(true);
    setCurrentIndex(index);
  };

  const handleCloseImage = () => {
    setIsOpen(false);
    setCurrentIndex(null);
  };

  const handleNextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
  };

  const handleKeyDown = (e) => {
    if (isOpen) {
      if (e.key === "Escape") {
        // Escape key
        handleCloseImage();
      } else if (e.key === "ArrowRight") {
        // Left arrow key
        handlePrevImage();
      } else if (e.key === "ArrowLeft") {
        // Right arrow key
        handleNextImage();
      }
    }
  };

  return (
    <>
      <div>
        <h1 className="mt-20 text-3xl text-white sm:text-center text-center first-letter">
          Photos
        </h1>
      </div>
      <div
        className="sm:pl-48 pl-10 sm:pr-48 pr-10 mt-10 mb-20"
        onKeyDown={handleKeyDown}
        tabIndex={0}>
        <div
          data-aos="fade-up"
          data-aos-duration="2500"
          className="container grid grid-cols-3 gap-4 mx-auto">
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full rounded hover:scale-105"
              onClick={() => handleOpenImage(index)}
              tabIndex={0}>
              <img src={image} alt="rooms"></img>
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <div className="sm:fixed absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center z-10">
          <div className="sm:relative  sm:w-1/2 w-80 border border-black  md:top-0 md:bottom-0 ">
            <button
              className="absolute md:right-0 right-3 md:top-0 top-52 mt-2 mr-2 sm:text-gray-900 text-white md:hover:text-buttons sm:text-3xl text-2xl font-bold"
              onClick={handleCloseImage}>
              X
            </button>
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 mt-2 sm:ml-2 ml-3 sm:text-gray-900 text-white hover:text-buttons  sm:text-5xl text-4xl font-bold"
              onClick={handlePrevImage}>
              &lt;
            </button>
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 mt-2 sm:mr-2 mr-3 sm:text-gray-900 text-white hover:text-buttons  sm:text-5xl text-4xl font-bold"
              onClick={handleNextImage}>
              &gt;
            </button>
            <img
              src={images[currentIndex]}
              alt="rooms"
              className=" justify-center"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Gallery;
