import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./About";
import Photos from "./Photos";
import Catalog from "./Catalog";
import Home from "./Home";
import LoadingScreen from "./LoadingScreen";
import TagManager from "react-gtm-module";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const tagManagerArgs = {
    gtmId: "G-KRCHCB62TJ",
  };
  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="font-default h-full w-full overflow-x-hidden">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/about" element={<About />} />
          </Routes>
          <Routes>
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/catalog/:category" element={<Catalog />} />
            <Route path="/catalog/:category/:id" element={<Catalog />} />
          </Routes>
          <Routes>
            <Route path="/photos" element={<Photos />} />
          </Routes>
        </div>
      )}
    </>
  );
}
export default App;
