import { useState, useEffect } from "react";
import CardsData from "./CardsData";
import logo from "./media/logo.png";
import { Twirl as Hamburger } from "hamburger-react";

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 860) {
        setIsNavExpanded(false);
      }
      if (window.innerWidth < 860) {
        setIsNavExpanded(false);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  // Add this function to get the current URL path
  const getCurrentPath = () => {
    const path = window.location.pathname;
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);

  //Search

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchResultsVisible(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultsVisible && !event.target.closest(".search-results")) {
        setSearchResultsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchResultsVisible]);
  const filteredCards = CardsData.filter((card) => {
    // Filter cards based on search query
    const searchTerm = searchQuery.toLowerCase();
    const title = card.title.toLowerCase() + card.title.toLocaleUpperCase();
    const description = card.description.toLowerCase();
    const category = card.category.toLowerCase();
    const carpet = card.nestedCard.carpet.toLowerCase();

    return (
      title.includes(searchTerm) ||
      description.includes(searchTerm) ||
      category.includes(searchTerm) ||
      carpet.includes(searchTerm)
    );
  }).slice(0, 4);

  // Add this variable to store the current path
  const currentPath = getCurrentPath();
  return (
    <div className="App sm:pl-44 pl-0 sm:pr-44 pr-0 ">
      <nav class="bg-nav border-gray-200 px-2  py-2.5  dark:bg-nav sm:flex-wrap flex-none ">
        <div class="md:container flex items-center justify-between mx-auto">
          <a href="/" class="flex items-center sm:mt-0 mt-4 ">
            <img src={logo} class="mr-3 h-14 sm:h-28 " alt="Vjosa Logo" />
          </a>
          {/* Search Mobile Version */}
          <div
            className={isSearchExpanded ? "  flex justify-center  " : "hidden"}
            id="navbar-default">
            {" "}
            <input
              type="text"
              id="search-navbar"
              class=" w-40 p-2 pl-10 mt-4    md:hidden  text-base text-white flex justify-center border border-nav rounded-full shadow-xl bg-footer focus:outline-none focus:ring-2 focus:ring-buttons dark:text-white "
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />{" "}
            {searchResultsVisible && (
              <div className="absolute mt-14 z-50">
                {filteredCards.map((card, index) => (
                  <div
                    class=" font-default "
                    onClick={() =>
                      (window.location.href = `/catalog/${card.category}/${card.nestedCard.carpet}`)
                    }>
                    <ul class=" flex-col text-left  w-full mr-2 ml-2    border text-white border-buttons  bg-nav ">
                      <div className=" grid grid-flow-col ">
                        <li>
                          <img class=" h-40 w-auto " src={card.image} alt="" />
                        </li>
                        <div className="my-auto pl-2 pr-4 ">
                          <li>
                            <p class="">{card.nestedCard.carpet}</p>
                          </li>
                          <li>
                            <h5 class="text-left ">{card.title}</h5>
                          </li>
                          <li>
                            <p class="">{card.description}</p>
                          </li>
                        </div>
                      </div>
                    </ul>
                  </div>
                ))}
                {filteredCards.length === 4 && (
                  <button
                    onClick={() => (window.location.href = `/catalog/`)}
                    className=" font-default  text-white text-lg w-full felx justify-items-center  mr-2 ml-2 border border-buttons   bg-footer hover:bg-buttons  py-4 px-4">
                    See More
                  </button>
                )}
              </div>
            )}
          </div>
          <div class="md:flex md:order-2 order-none sm:mt-0 mt-4 ">
            {/*Mobile Version Search*/}
            <button
              className=" relative left-0 md:hidden mr-2  "
              onClick={() => {
                setIsSearchExpanded(!isSearchExpanded);
              }}>
              <svg
                className="w-10 h-10 text-white -mt-11"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"></path>
              </svg>
              <span class="sr-only">Search icon</span>
              <span class="sr-only">Search</span>
            </button>
            {/* Mboile Version Burge */}
            <button
              class=" items-center  p-1 text-sm text-white rounded-lg md:hidden  "
              aria-controls="navbar-default"
              aria-expanded="false">
              <span class="sr-only ">Open menu</span>
              <div className="">
                <Hamburger
                  label="Show menu"
                  easing="ease-in"
                  toggled={isNavExpanded}
                  toggle={setIsNavExpanded}
                  size={30}
                  distance="md"
                  color="#ffff"
                  duration={0.5}></Hamburger>
              </div>
            </button>
            {/* Dektop Version */}
            <div class="relative hidden md:block">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Search icon</span>
              </div>
              <input
                type="text"
                id="search-navbar"
                class="block w-full p-4 pl-10 text-sm text-white border border-nav rounded-full shadow-xl bg-footer focus:outline-none focus:ring-2 focus:ring-buttons   dark:text-white "
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          {/* Desktop Version */}
          <div className={"hidden lg:block"} id="navbar-default">
            <ul class="md:flex md:flex-row flex-col  p-4 mt-4 border text-white border-buttons rounded-lg bg-nav md:space-x-8 md:mt-0 md:text-lg    md:border-0 md:bg-transparent">
              <li>
                <a
                  href="/"
                  class={`block py-2 pl-3 pr-4 text-white   md:hover:bg-transparent md:border-0 md:hover:text-white md:p-0 hover:md:border-b border-buttons ${
                    currentPath === "/"
                      ? "text-buttons  border-b border-buttons"
                      : ""
                  }`}>
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  class={`block py-2 pl-3 pr-4 text-white   md:hover:bg-transparent md:border-0  md:p-0 hover:md:border-b border-buttons ${
                    currentPath === "/about"
                      ? "text-buttons  border-b border-buttons"
                      : ""
                  }`}>
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/catalog"
                  class={`block py-2 pl-3 pr-4 text-white   md:hover:bg-transparent md:border-0  md:p-0 hover:md:border-b border-buttons ${
                    currentPath === "/catalog"
                      ? "text-buttons  border-b border-buttons"
                      : ""
                  }`}>
                  Catalog
                </a>
              </li>
              <li>
                <a
                  href="/photos"
                  class={`block py-2 pl-3 pr-4 text-white   md:hover:bg-transparent md:border-0  md:p-0 hover:md:border-b border-buttons ${
                    currentPath === "/photos" ? "text-buttons   " : ""
                  }`}>
                  Photos
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Mobile Version */}
      <div
        className={isNavExpanded ? " sm:block flex justify-center  " : "hidden"}
        id="navbar-default">
        <ul class=" flex-col text-left w-full mr-2 ml-2   mt-2 border text-white border-nav rounded-lg bg-buttons ">
          <li>
            <a
              href="/"
              class={`block py-2 pl-3 pr-2 text-white  hover:bg-transparent border-0 hover:text-buttons  border-buttons  ${
                currentPath === "/" ? "text-white bg-button   " : ""
              }`}
              onClick={() => {
                setIsNavExpanded(false);
              }}>
              Home
            </a>
          </li>
          <li>
            <a
              href="/about"
              class={`block py-2 pl-3 pr-2 text-white  hover:bg-transparent border-0 hover:text-buttons  border-buttons  ${
                currentPath === "/about" ? "text-white bg-button   " : ""
              }`}
              onClick={() => {
                setIsNavExpanded(false);
              }}>
              About Us
            </a>
          </li>
          <li>
            <a
              href="/catalog"
              class={`block py-2 pl-3 pr-2 text-white  hover:bg-transparent border-0 hover:text-buttons  border-buttons  ${
                currentPath === "/catalog" ? "text-white bg-buttons   " : ""
              }`}
              onClick={() => {
                setIsNavExpanded(false);
              }}>
              Catalog
            </a>
          </li>
          <li>
            <a
              href="/photos"
              class={`block py-2 pl-3 pr-2 text-white  hover:bg-transparent border-0   border-buttons  ${
                currentPath === "/photos" ? "text-white bg-buttons   " : ""
              }`}
              onClick={() => {
                setIsNavExpanded(false);
              }}>
              Photos
            </a>
          </li>
        </ul>
      </div>

      {/* Search Desktop Version */}
      <div
        className={
          isSearchExpanded ? "  hidden  " : "md:block flex justify-center -mt-9"
        }
        id="navbar-default">
        {" "}
        {searchResultsVisible && (
          <div className="absolute right-48 z-50  ">
            {filteredCards.map((card, index) => (
              <div
                class=" font-default  "
                onClick={() => {
                  const nestedCardUrl = `/catalog/${card.category}/${card.nestedCard.carpet}`;
                  console.log(nestedCardUrl);
                  window.location.href = nestedCardUrl;
                }}>
                <ul class=" flex-col text-left  w-full ml-2 mr-2  hover:bg-buttons   border text-white border-buttons bg-nav ">
                  <div className=" grid grid-flow-col  " href>
                    <li>
                      <img class=" h-36 w-fit " src={card.image} alt="" />
                    </li>
                    <div className="my-auto">
                      <li>
                        <p class="">{card.nestedCard.carpet}</p>
                      </li>
                      <li>
                        <h5 class="text-left ">{card.title}</h5>
                      </li>
                      <li>
                        <p class="">{card.description}</p>
                      </li>
                      <li>
                        <p class="">{card.nestedCard.dimmessions}</p>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            ))}
            {filteredCards.length === 4 && (
              <button
                onClick={() => (window.location.href = `/catalog/`)}
                className=" font-default  text-white text-lg w-full felx justify-items-center mr-2 ml-2 border border-buttons  bg-footer hover:bg-buttons  py-4 px-4">
                See More
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
