import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Acard from "./Acard";
import Footer from "./Footer";

function About() {
  return (
    <div className="bg-nav">
      <Header />
      <Navbar />
      <Acard />
      <Footer />
    </div>
  );
}
export default About;
